/**
 * The base class for all of the story enriched element of
 * json counterpart like story, scene, task.
 */

import _ from 'lodash'
import { PlatformType } from './storyboard-plan'

const propHandler = {
  get(prop: any): any {
    if (this) {
      return this[prop]
    }
  },
  set(prop: any, _) {
    throw new Error(`Cannot update ${prop}.  Readonly`)
  },
}

export interface IStoryboardElementStatus {
  status: 'unknown' | 'satisfied' | 'unsatisfied'
}

export interface IStoryboardEnrichProps {
  getElement: (id) => any
}
export abstract class StoryboardElement {
  public readonly data: any
  protected handler: any
  protected schema: any

  constructor(data: any, schema = {}) {
    this.data = data
    this.schema = schema
    this.handler = propHandler

    this.registerOwnProperties()
  }

  /**
   * Perform post creation initalization.  In the json, in each component, there is a lot
   * of pointer/edge that basically point to another component.  The enrich() is basically
   * run after all the objects creation to resolve the edge/pointer
   * @param props
   */
  public abstract enrich(props: IStoryboardEnrichProps): void

  public toString() {
    const id = _.get(this.data, 'id', 'unknown_id')
    const name = _.get(this.data, 'name', 'unknown_name')
    return `${id}.${name}`
  }

  public get(path: string, defaultValue?: any) {
    return _.get(this.data, path, defaultValue)
  }

  public get hasUISchemaMobile(): boolean {
    const uiSchema = this.uiSchemaMobile
    return !_.isEmpty(uiSchema)
  }

  public get hasUISchema(): boolean {
    return !_.isEmpty(this.uiSchemaMobile) || !_.isEmpty(this.uiSchemaWeb)
  }

  public get uiSchemaMobile(): any {
    return _.omit(_.get(this.data, 'uiView.mobile.uiSchema', {}), ['overlaySection' ])
  }

  public get uiInterstitialSyncSchemaMobile(): any {
    return _.get(this.data, 'uiView.mobile.interstitialSyncSchema')
  }

  public get uiNavigationOptionsMobile(): any {
    return _.get(this.data, 'uiView.mobile.navigationOptions')
  }

  public get uiOverlaySchemaMobile(): any {
    // don't remember why overlay is under 'uiSchema', but moving it out to its own, support old version
    // 'mobile.uiSchema.overlaySection' and a newer one 'mobile.overlaySchema'
    return _.get(this.data, 'uiView.mobile.overlaySchema') || _.get(this.data, 'uiView.mobile.uiSchema.overlaySection')
  }

  public get uiSchemaWeb(): any {
    return _.get(this.data, 'uiView.web.uiSchema', {})
  }

  public get uiSchemaMobileWeb(): any {
    return _.get(this.data, 'uiView.mobileWeb.uiSchema', {})
  }

  // Custom implementations for tasks and scenes
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public platformSchema(platform: PlatformType) { return }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public uiSchema(platform: PlatformType) { return }

  /**
   * Register the properties from data to make it easier to access directly
   */
  protected registerOwnProperties() {
    const schemaProperties = _.get(this.schema, 'properties', {})

    _.forEach(schemaProperties, (prop, key) => this.registerProperty(key))
  }

  protected registerProperty(prop: string) {
    const target = this.data
    const getter = this.handler.get
    const setter = this.handler.set
    Object.defineProperty(this, prop, {
      configurable: true,
      enumerable: true,
      get: getter.bind(target, prop),
      set: setter.bind(target, prop),
    })
  }
}

import _ from 'lodash'
import escodegen from 'escodegen'
import * as esprima from 'esprima-next'
import moment from 'moment-timezone'
import numeral from 'numeral'
import {
  parsePhoneNumberFromString as PhoneNumberParse,
} from 'libphonenumber-js'

import { JSONSchemaResolver } from '../resolvers/json-schema-resolver'
import { Try } from './utils'

class FormatterImpl {

  private typeFormatters = {}
  private edgeFormatters = {}
  private ENTITY_ID = '/1.0/entities/metadata/entity.json'
  private showGPSAsDegrees = false
  private twigFormulas = {} // todo - long-term this might be better as a size-bounded cache
  private Twig: any

  constructor() {
    this.Twig = Try(() => require('twig/twig.min'))
  }

  public registerTypeFormatter(type, formatter: (value: any, schema?: any, context?: any) => any) {
    this.typeFormatters[type] = formatter
  }

  public registerEdgeFormatter(entityType, formatter: (value: any) => any) {
    this.edgeFormatters[entityType] = formatter
  }

  public setShowGPSAsDegree(showGPSAsDegrees) {
    this.showGPSAsDegrees = showGPSAsDegrees
  }

  public formatEntityEdge = (edge, schema, context?) => {
    const entityType = schema.entityType
    if (this.edgeFormatters[entityType]) {
      return this.edgeFormatters[entityType](edge, context)
    }
    return edge.displayName
  }

  public formatEntityValue = (value, schema, context?) => {
    if (_.isNil(value)) { return }
    const ref = schema[JSONSchemaResolver.REF_KEY]
    if (this.typeFormatters[ref]) {
      return this.typeFormatters[ref](value, schema, context)
    } else if (this.typeFormatters[schema.type]) {
      return this.typeFormatters[schema.type](value, schema, context)
    }
  }

  public formatValue(value, type, schema, context?) {
    return this.typeFormatters[type](value, schema, context)
  }

  public formatBoolean(value) {
    return !_.isNil(value) ? (value ? 'Yes' : 'No') : ''
  }

  public formatContact(value) {
    const { email, phoneNumber, firstName, lastName } = value
    const phone = _.get(phoneNumber, 'phone')
    if (firstName && lastName) {
      return `${firstName} ${lastName}`
    }
    return email || phone
  }

  public formatMoney(value) {
    if (_.isString(value)) { value = parseFloat(value) }
    if (_.isNil(value) || _.isNaN(value) || !_.isFinite(value)) {
      return '0.00'
    }
    return numeral(value).format('0,0.00')
  }

  public formatNumber(value) {
    if (_.isNil(value) || _.isNaN(value) || !_.isFinite(value)) {
      return '–'
    }
    return value
  }

  public formatDistance(value) {
    if (_.isNil(value) || _.isNaN(value) || !_.isFinite(value)) {
      return '–'
    }
    return numeral(value).format('0,0')
  }

  public formatPercent(value) {
    if (_.isNil(value) || _.isNaN(value) || !_.isFinite(value)) {
      return '–'
    }
    value = parseFloat(value) * 100
    return `${value.toFixed(2)}%`
  }

  public formatPhoneNumber(phone) {
    const parsedPhoneNumber = PhoneNumberParse(phone, 'US')
    return parsedPhoneNumber.format('NATIONAL')
  }

  public formatTelUrl(props) {
    if (!props) return ''
    const { phone, extension } = props
    if (_.isEmpty(phone)) return ''
    // TODO(Peter): libphonejs uses for_of loop on a string and due to a crazy
    // core-js bug on IE11, it classof(phone) is an Array Iterator instead of
    // store. We are casting phone to Object to bypass this stupid bug
    // https://github.com/catamphetamine/libphonenumber-js/blob/95c4195ac5670888c88edde4aa390adaa81b02af/source/parse.js#L376
    // https://github.com/zloirock/core-js/blob/e44fc0575d566508488d3900baee86aac941e4fd/library/modules/_classof.js#L14
    const parsedPhoneNumber = PhoneNumberParse(phone, 'US')
    const formattedPhoneNumber = parsedPhoneNumber.format('INTERNATIONAL')
    const ext = _.isEmpty(extension) ? '' : `;ext=${extension.trim()}`
    return `tel:${formattedPhoneNumber}${ext}`
  }

  public formatWithTwig(template, data) {
    if (!this.twigFormulas[template]) {
      this.twigFormulas[template] = this.Twig.twig({ data: template })
    }

    return this.twigFormulas[template].render(data)
  }

  /////////////////////////////////////////////////////////////////////////////
  // Date Formatting
  /////////////////////////////////////////////////////////////////////////////

  public formatDate(value, format = 'MM/DD/YYYY') {
    return moment(value).format(format)
  }

  public formatDateTime(value, schema?, context?) {
    if (value) {
      let momentVal = moment(value)
      if (context && context.timezone) {
        momentVal = momentVal.tz(context.timezone)
      }
      return momentVal.format('MM/DD/YYYY HH:mm z')
    } else {
      return ''
    }
  }

  // 01/10/2020 08:00
  // 01/10/20 8:00–10:30
  // 01/04/2020 08:00 – 01/05/2020 10:30
  // 01/04/2020 at 8AM – 01/05/2020 at 10:30PM
  public formatDateRange(value, isMilitaryTime = true, useShortForm = false, showTimezone = true) {
    if (!value || !value.start || !value.end) {
      return ''
    }
    const { start, end } = value
    const startTimezone = start.timezone || moment["defaultZone"].name
    const endTimezone = end.timezone || moment["defaultZone"].name
    const startTime = moment.tz(start.dateTime, moment.ISO_8601, startTimezone)
    const endTime = moment.tz(end.dateTime, moment.ISO_8601, endTimezone)
    const isSameTimezone = startTime.format('z') === endTime.format('z')
    const tzStartFormat = (showTimezone && !isSameTimezone) ? ` z` : ''
    const tzEndFormat = showTimezone ? ` z` : ''

    let startTimeFormat, endTimeFormat
    if (isMilitaryTime) {
      startTimeFormat = 'HH:mm'
      endTimeFormat = 'HH:mm'
    } else {
      startTimeFormat = startTime.minutes() > 0 ? 'h:mm' : 'h'
      endTimeFormat = endTime.minutes() > 0 ? 'h:mm' : 'h'
      const isStartTimeAM = startTime.hours() < 12
      const isEndTimeAM = endTime.hours() < 12
      if (isStartTimeAM !== isEndTimeAM) {
        startTimeFormat = `${startTimeFormat}A`
      }
      endTimeFormat = `${endTimeFormat}A`
    }
    startTimeFormat = `${startTimeFormat}${tzStartFormat}`
    endTimeFormat = `${endTimeFormat}${tzEndFormat}`

    const formattedStartTime = startTime.format(startTimeFormat)
    const formattedEndTime = endTime.format(endTimeFormat)
    const dateFormat = useShortForm ? 'MM/DD/YY' : 'MM/DD/YYYY'
    const formattedStartDate = startTime.format(dateFormat)
    const formattedEndDate = endTime.format(dateFormat)
    const at = useShortForm ? 'at' : null
    const intervalSeparator = showTimezone && !isSameTimezone ? ' ' : ''
    const formattedTimeInterval = _.join([formattedStartTime, '–', formattedEndTime], intervalSeparator)

    const parts = []
    if (startTime.isSame(endTime, 'year') && startTime.isSame(endTime, 'day')) {
      if (startTime.isSame(endTime, 'minute') && isSameTimezone) {
        parts.push(formattedStartDate, at, formattedEndTime)
      } else {
        parts.push(formattedStartDate, at, formattedTimeInterval)
      }
    } else {
      parts.push(formattedStartDate, at, formattedStartTime, '–', formattedEndDate, at, formattedEndTime)
    }
    return _.join(_.compact(parts), ' ')
  }

  // TODO(Dan): refactor out the duplication with formatDateRange.
  public formatTimeInterval(value, isMilitaryTime, showTimezone = false) {
    const { start, end } = value
    if (!start || !end) {
      return ''
    }
    const startTimezone = start.timezone || moment["defaultZone"].name
    const endTimezone = end.timezone || moment["defaultZone"].name
    const isSameTimezone = startTimezone === endTimezone
    const tzStartFormat = (showTimezone && !isSameTimezone) ? ` z` : ''
    const tzEndFormat = showTimezone ? ` z` : ''
    const startTime = this.parseDateTimeWithTimezone(start)
    const endTime = this.parseDateTimeWithTimezone(end)
    let startTimeFormat, endTimeFormat
    if (isMilitaryTime) {
      startTimeFormat = 'HH:mm'
      endTimeFormat = 'HH:mm'
    } else {
      startTimeFormat = startTime.minutes() > 0 ? 'h:mm' : 'h'
      endTimeFormat = endTime.minutes() > 0 ? 'h:mm' : 'h'
      const isStartTimeAM = startTime.hours() < 12
      const isEndTimeAM = endTime.hours() < 12
      if (isStartTimeAM !== isEndTimeAM) {
        startTimeFormat = `${endTimeFormat}A`
      }
      endTimeFormat = `${endTimeFormat}A`
    }
    startTimeFormat = `${startTimeFormat}${tzStartFormat}`
    endTimeFormat = `${endTimeFormat}${tzEndFormat}`
    const formattedStartTime = startTime.format(startTimeFormat)
    const formattedEndTime = endTime.format(endTimeFormat)
    const separator = showTimezone && !isSameTimezone ? ' ' : ''
    return _.join([formattedStartTime, '–', formattedEndTime], separator)
  }

  /**
   * @param value - An array of hoursOfOperation structures.
   * @return An array of formatted strings.
   */
  public formatHoursOfOperation(value: any[]): string[] {
    const dayAbbreviator = (day) => {
      let letter = day[0]
      if (day === "Sunday") letter = "U"
      if (day === "Thursday") letter = "R"
      return letter
    }
    const lines = []
    _.forEach(value, item => {
      let daysOfWeek = item.daysOfWeek || []
      daysOfWeek = _.join(_.map(daysOfWeek, dayAbbreviator), "")
      let time
      if (item.isOpen24Hours) {
        time = "24h"
      } else {
        // 0600-1130, 1330-1800
        const hours = item.hours
        time = _.join(_.map(hours, interval => this.formatTimeInterval(interval, true)))
      }
      // "24h MWF" or "0600-1130[, 1330-1800, ...] MWF"
      const line = _.join([time, daysOfWeek], " ")
      if (time && line) lines.push(line)
    })
    return lines
  }

  /////////////////////////////////////////////////////////////////////////////
  // Location Formatting
  /////////////////////////////////////////////////////////////////////////////

  public formatAddress(address, showGPSAsDegrees?) {
    const geolocation = address.geolocation
    if (address.streetAddress && address.locality && address.region) {
      return `${address.streetAddress} ${address.locality}, ${address.region}`
    } else if (address.locality && address.region) {
      return `${address.locality}, ${address.region}`
    } else if (geolocation && geolocation.latitude && geolocation.longitude) {
      return this.formatGeolocation(geolocation, showGPSAsDegrees)
    }
  }

  // Format GPS Location to displayable format
  public formatGeolocation({ latitude, longitude }, showGPSAsDegrees) {
    showGPSAsDegrees = _.isNil(showGPSAsDegrees) ? this.showGPSAsDegrees : showGPSAsDegrees
    if (latitude && longitude && (latitude !== 0) && (longitude !== 0)) {
      if (showGPSAsDegrees) {
        return this.decimalDegreeToDegreesMinutesSeconds(latitude, longitude)
      } else {
        return `${this.truncateNumber(latitude, 6)}, ${this.truncateNumber(longitude, 6)}`
      }
    }
  }

  public formatGeolocationAsGoogleMapUrl(geolocation) {
    if (!geolocation) { return }
    const locationLatLng = `${geolocation.latitude},${geolocation.longitude}`
    return `https://www.google.com/maps?q=${locationLatLng}`
  }

  public formatGeolocationAsAppleMapUrl(geolocation) {
    if (!geolocation) { return }
    const locationLatLng = `${geolocation.latitude},${geolocation.longitude}`
    return `http://maps.apple.com/?q=${locationLatLng}`
  }

  public camelCaseToWord(name) {
    if (_.isEmpty(name)) { return }
    name = name.replace(/([A-Z])/g, ($1) => ' ' + $1.toUpperCase())
    return name.charAt(0).toUpperCase() + name.slice(1)
  }

  public pad(num, places, char='0') {
    const zero = places - num.toString().length + 1
    return Array(+(zero > 0 && zero)).join(char) + num
  }

  public parseDateTimeWithTimezone(dateTimeWithTimezone) {
    const { dateTime, timezone } = dateTimeWithTimezone
    if (timezone) {
      return moment.tz(dateTime, moment.ISO_8601, timezone)
    }
    return moment(dateTime, moment.ISO_8601, true)
  }

  // This function returns the coordinate
  // conversion string in DD to DMS.
  private decimalDegreeToDegreesMinutesSeconds(lat, lng) {
    lat = parseFloat(lat)
    lng = parseFloat(lng)

    let latResult = (lat >= 0) ? 'N' : 'S'
    // Call to getDms(lat) function for the coordinates of Latitude in DMS.
    // The result is stored in latResult variable.
    latResult += this.getDegreeToDegreesMinutesSeconds(lat)

    let lngResult = (lng >= 0) ? 'E' : 'W'
    // Call to getDms(lng) function for the coordinates of Longitude in DMS.
    // The result is stored in lngResult variable.
    lngResult += this.getDegreeToDegreesMinutesSeconds(lng)

    return latResult + ' ' + lngResult
  }

  private getDegreeToDegreesMinutesSeconds(val) {
    val = Math.abs(val)
    const valDeg = Math.floor(val)
    const valMin = Math.floor((val - valDeg) * 60)
    const valSec = Math.round((val - valDeg - valMin / 60) * 3600 * 1000) / 1000
    return `${valDeg}º ${valMin}' ${valSec}"`
  }

  private truncateNumber(number, decimals) {
    number = parseFloat(number)
    return number.toFixed(decimals)
  }

}

export const Formatter = new FormatterImpl()

// simple object formatter for objects with a single key
Formatter.registerTypeFormatter('object', (value, schema) => {
  const schemaProps = schema.properties
  const keys = _.keys(value)
  if (keys.length !== 1) {
    return
  }
  const key = keys[0]
  return Formatter.formatEntityValue(value[key], schemaProps[key])
})

Formatter.registerTypeFormatter('array', (value, schema) => {
  if (!_.isArray(value)) { value = [value] }
  const itemSchema = schema.items
  value = _.map(value, (item) => Formatter.formatEntityValue(item, itemSchema))
  value = _.filter(value, _.isString)
  return value.join(', ')
})

Formatter.registerTypeFormatter('boolean', (value) => {
  return Formatter.formatBoolean(value)
})

Formatter.registerTypeFormatter('date', (value) => {
  return Formatter.formatDate(value)
})

Formatter.registerTypeFormatter('date-time', (value, schema, context) => {
  return Formatter.formatDateTime(value, schema, context)
})

Formatter.registerTypeFormatter('integer', (value) => {
  return value
})

Formatter.registerTypeFormatter('currency', (value) => {
  return Formatter.formatMoney(value)
})

Formatter.registerTypeFormatter('number', (value) => {
  return Formatter.formatNumber(value)
})

Formatter.registerTypeFormatter('distance', (value) => {
  return Formatter.formatDistance(value)
})

Formatter.registerTypeFormatter('percent', (value) => {
  return Formatter.formatPercent(value)
})

Formatter.registerTypeFormatter('string', (value, schema, context) => {
  if (_.has(schema, 'enumOptions')) {
    const option: any = _.find(schema.enumOptions, { value })
    return option ? option.label : value
  } else if (_.has(schema, 'format')) {
    if (schema.format === 'date') {
      return Formatter.formatDate(value)
    } else if (schema.format === 'date-time') {
      return Formatter.formatDateTime(value, schema, context)
    }
  }
  return value
})

Formatter.registerTypeFormatter('/1.0/entities/metadata/entity.json#/definitions/address', (value) => {
  return Formatter.formatAddress(value)
})

Formatter.registerTypeFormatter('/1.0/entities/metadata/entity.json#/definitions/deferredUser', (value) => {
  return value ? `${value.firstName} ${value.lastName}` : ''
})

Formatter.registerTypeFormatter('/1.0/entities/metadata/entity.json#/definitions/edge', (value, schema, context) => {
  return Formatter.formatEntityEdge(value, schema, context)
})

Formatter.registerTypeFormatter('/1.0/entities/metadata/entity.json#/definitions/labeledLocation', (value) => {
  const address: any = _.get(value, 'value.denormalizedProperties["location.address"]', {})
  return Formatter.formatAddress(address)
})

Formatter.registerTypeFormatter('/1.0/entities/metadata/entity.json#/definitions/labeledEmail', (value) => {
  return value.value
})

Formatter.registerTypeFormatter('/1.0/entities/metadata/entity.json#/definitions/labeledIdentifier', (value) => {
  return value.value
})

Formatter.registerTypeFormatter('/1.0/entities/metadata/entity.json#/definitions/labeledPhoneNumber', (value) => {
  return _.get(value, 'value.phone')
})

Formatter.registerTypeFormatter('/1.0/entities/metadata/entity.json#/definitions/dateTimeInterval', (value) => {
  return Formatter.formatDateRange(value)
})

Formatter.registerTypeFormatter('/1.0/entities/metadata/user.json#/definitions/verifiableEmail', (value) => {
  return value.value
})

Formatter.registerTypeFormatter('/1.0/entities/metadata/user.json#/definitions/verifiablePhoneNumber', (value) => {
  return _.get(value, 'value.phone')
})

/**
 * @throws SyntaxError if the input JSON is invalid.
 * @returns A formatted JSON string.
 */
export function formatJsonString(value: string): string {
  const json = JSON.parse(value)
  return JSON.stringify(json, null, 2)
}

/**
 * @throws Error if the input JavaScript is invalid.
 * @returns A formatted JavaScript string.
 */
export function formatJavascriptString(value: string): string {
  const ast = esprima.parse(value)
  return escodegen.generate(ast)
}

declare let window: any

import $ from 'jquery'
import _ from 'lodash'
import moment from 'moment-timezone'
import Platform from 'platform'
import queryString from 'query-string'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { ViewRenderer } from 'shared-libs/components/view/renderer'

import apis from 'browser/app/models/apis'
import { Settings } from 'browser/app/models/settings'
import { browserHistory } from 'browser/history'

import ComponentsMap from 'browser/components'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import OverlayManager from 'browser/components/atomic-elements/organisms/overlay-manager/overlay-manager'
import { InboxProvider } from 'browser/contexts/inbox/inbox-provider'
import { clearCookie } from 'browser/app/utils/utils'

// Routes
import { Activity as ActivityPage } from 'browser/app/pages/app/activity'
import { EntityIndex as EntityPage, EntitySinglePage } from 'browser/app/pages/app/entity'
import { Integrations as IntegrationsPage } from 'browser/app/pages/app/integrations'
import { Onboarding as OnboardingPage } from 'browser/app/pages/app/onboarding'
import MotiveRegister from 'browser/app/pages/app/motive'
import { SearchIndex as SearchPage } from 'browser/app/pages/app/search'
import { Settings as SettingsPage } from 'browser/app/pages/app/settings'
import { Tools as AdminToolsPage } from 'browser/app/pages/app/tools'
import { ViewsIndex as ViewPage } from 'browser/app/pages/app/views'
import { GetMobileApp } from 'browser/app/pages/mobile/get-mobile-app/get-mobile-app'
import { getAuthorization, normalizeRedirectPath } from '../utils/auth'
import { LocalStorageProvider } from 'browser/contexts/local-storage/local-storage-provider'
import { Insights } from './app/insights'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { PlatformType } from 'shared-libs/models/types/storyboard/storyboard-plan'
import { Logger } from 'browser/apis/logging'
import { ViewContext } from 'browser/contexts/view-context'
import { FlagsmithContext } from 'react-native-flagsmith/react'
import { IFlagsmith } from 'react-native-flagsmith/types'

const SentryRoute = Sentry.withSentryRouting(Route);

interface IAppNavigatorProps extends IBaseProps {
  location: any
  match: any
}

interface IAppNavigatorState {
  isLoading: boolean
  layout: any
}

export class AppNavigator extends React.Component<IAppNavigatorProps, IAppNavigatorState> {

  private settings: Settings
  private flagsmithClient: IFlagsmith

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      layout: null,
    }
    OverlayManager.pushReactHost(this)
    this.settings = new Settings(this.getTimezone(props), PlatformType.WEB)
  }

  public componentDidMount() {
    this.installAjaxHooks()
    this.bootstrap()
  }

  public componentWillUnmount() {
    this.removeAjaxHooks()
    OverlayManager.popReactHost()
  }

  public render() {
    const { isLoading } = this.state
    const { location } = this.props
    if (isLoading) {
      return <LoadingSpinner />
    } else if (this.shouldHideApplicationView) {
      return this.renderRoutes()
    }
    return this.renderApplicationView()
  }

  private get shouldHideApplicationView() {
    const { isPrint } = queryString.parse(location.search)
    const isOnboarding = location.pathname.startsWith('/onboarding')
    const isMobileSamlLogin = location.pathname.startsWith('/mobile/saml-login-loading')
    return isPrint || isOnboarding || isMobileSamlLogin
  }

  private renderApplicationView() {
    const { layout } = this.state
    const renderState = {
      ...this.state,
      firm: this.settings.getFirm(),
      isFirmAdmin: this.settings.isFirmAdmin,
      location,
      settings: this.settings,
      user: this.settings.getUser(),
    }
    return (
      <AppNavigatorContext.Provider
        value={{settings: this.settings}}
      >
        <LocalStorageProvider>
          <InboxProvider inboxConfig={this.settings.getInboxConfiguration()} userId={_.get(this.settings.getUser(), 'uniqueId')}>
            <ViewRenderer
              actions={this}
              componentsMap={ComponentsMap}
              state={renderState}
              schema={layout}
              api={apis}
            >
              {this.props.children}
              {this.renderRoutes()}
            </ViewRenderer>
          </InboxProvider>
        </LocalStorageProvider>
      </AppNavigatorContext.Provider>
    )
  }

  private renderRoutes() {
    const { location } = this.props
    return (
      <>
        <FlagsmithContext.Consumer>
          {flagsmithClient => {
            this.flagsmithClient = flagsmithClient
            if (!_.isNil(this.settings)) {
              this.settings.updateFlagsmith(this.flagsmithClient)
            }
            return <></>
          }}
        </FlagsmithContext.Consumer>
        <AppNavigatorContext.Provider value={{settings: this.settings}}>
          <Switch>
            <SentryRoute path='/activity' render={this.renderActivityPage} />
            <SentryRoute path='/document/:entityId' component={EntityPage} />
            <SentryRoute path='/entity/:entityId' component={EntitySinglePage} />
            <SentryRoute path='/search' component={SearchPage} />
            <SentryRoute path='/settings' component={SettingsPage} />
            <SentryRoute path='/onboarding/get-mobile-app' component={GetMobileApp} />
            <SentryRoute path='/onboarding' component={OnboardingPage} />
            <SentryRoute path='/motive/register' component={MotiveRegister} />
            <SentryRoute path='/mobile/saml-login-loading' component={LoadingSpinner} />
            <SentryRoute path='/integrations' render={this.renderIntegrationsPage} />
            <SentryRoute path='/view/:viewId/custom/:customViewId' render={this.handleRenderViewPage} />
            <SentryRoute path='/view/:viewId' render={this.handleRenderViewPage} />
            {(this.settings.isAdmin || this.settings.isFirmAdmin) && <SentryRoute path='/insights/:entityId' component={Insights} />}
            {this.settings.isAdmin && <SentryRoute path='/tools' component={AdminToolsPage} />}
            <SentryRoute exact={true} render={this.handleRenderRedirectRoute} />
          </Switch>
        </AppNavigatorContext.Provider>
      </>
    )
  }

  private handleRenderViewPage = (props) => {
    return (
      <ViewContext.Provider value={{basePath: this.props.match.url}}>
        <ViewPage {...props}>
        </ViewPage>
      </ViewContext.Provider>
    )
  }

  private renderActivityPage = () => {
    const { location } = this.props
    return (
      <ActivityPage
        location={location}
        settings={this.settings}
      />
    )
  }

  private renderIntegrationsPage = () => {
    return (
      <IntegrationsPage
        settings={this.settings}
      />
    )
  }

  private bootstrap() {
    this.settings.initialize().then(() => {
      if (!_.isNil(this.flagsmithClient)) {
        this.settings.updateFlagsmith(this.flagsmithClient)
      }
      const bundle = this.settings.getApplicationBundle()
      return bundle.getView('application')
    }).then(({ uiSchema }) => {
      this.setState({
        isLoading: false,
        // TODO: Migrate application view schemas to use `uiSchema.web` instead of `uiSchema`.
        layout: uiSchema.web || _.omit(uiSchema, 'mobile'),
      })
    }).catch((error) => {
      console.error(error)
    })
  }

  private getTimezone(props) {
    const { location } = props
    const { timezone } = queryString.parse(location.search)
    // timezone might be passed in as query when generating pdf
    return timezone ? timezone : moment.tz.guess()
  }

  private handleRenderRedirectRoute = () => {
    const { samlLogin } = queryString.parse(location.search)
    let initialRoute = this.settings.getInitialRoute()
    if (!_.isEmpty(samlLogin) && this.isMobile) {
      initialRoute = '/mobile/saml-login-loading'
    } else if (this.isMobile) {
      initialRoute = '/onboarding/get-mobile-app'
    }
    return <Redirect to={initialRoute} />
  }

  private getOnboardingRoute(): string {
    const { samlLogin } = queryString.parse(location.search)
    if (!_.isEmpty(samlLogin)) {
      return '/mobile/saml-login-loading'
    } else {
      return '/onboarding/get-mobile-app'
    }
  }

  private installAjaxHooks() {
    const { location } = this.props
    const authorization = getAuthorization()
    apis.setupAuthorization(authorization)
    // setup global error handler for 401 redirection
    $(document).ajaxError((event, jqxhr, settings, error) => {
      const isOnSignIn = window.location.pathname.indexOf('/sign-in') !== -1
      // no need to redirect the user if they are already on a sign in page
      if (jqxhr.status === 401 && !isOnSignIn) {
        // log to help debug erroneous 401s, in case of regression on VD-6434,
        // where users are unexpectedly routed to sign-in.
        Logger.logEvent('Unauthorized Request Attempted', { url: jqxhr.url, location: location.href })

        clearCookie('Authorization')

        if (this.isMobile) {
          browserHistory.replace({
            pathname: this.getOnboardingRoute(),
          })
          this.setState({ isLoading: false })
        } else {
          browserHistory.replace({
            pathname: '/sign-in',
            state: { nextRoute: normalizeRedirectPath(window.location) }
          })
        }
      }
    })
  }

  private removeAjaxHooks() {
    $.ajaxSetup({
      beforeSend: null,
      cache: false,
      error: null,
    })
  }

  private get isMobile() {
    return Platform.os.family === 'iOS' || Platform.os.family === 'Android'
  }
}

/**
 * Storyboard navigation route enrichment of the json NavigationRoute counterpart
 */

import { IStoryboardEnrichProps, StoryboardElement } from './storyboard-element'
import { NavigationRoute } from './storyboard-plan-model'
export class StoryboardNavigationRoute extends StoryboardElement {
  private path: StoryboardElement // resolved path id

  constructor(data: NavigationRoute, schema: any = {}) {
    super(data, schema)
  }

  public enrich(props: IStoryboardEnrichProps): StoryboardNavigationRoute {
    const { getElement } = props

    const pathId = this.data.pathId
    this.path = getElement(pathId)
    return this
  }

  public isImplicitTask() {
    return !!this.data.isImplicitTask
  }

  public getPath(): StoryboardElement {
    return this.path
  }

  public isExit(): boolean {
    return this.data.pathId === '__EXIT__'
  }
}

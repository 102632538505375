import _ from 'lodash';
import { JSONSchemaResolver } from '../../resolvers/json-schema-resolver';


import { IAbstractRendererProps, AbstractRenderer } from '../abstract-renderer'

interface IBaseViewRendererProps {
    api?: any
}

type IViewRendererProps = IAbstractRendererProps & IBaseViewRendererProps

export class ViewRenderer extends AbstractRenderer<IViewRendererProps, any> {

  private schemaResolver: JSONSchemaResolver

  constructor(props: IViewRendererProps) {
    super(props)
    if (this.props.api) {
      this.schemaResolver = new JSONSchemaResolver(this.props.api);
    }
  }

  protected createChildFrame(frames, currentContext, currentState?) {
    if (!this.schemaResolver) {
      return super.createChildFrame(frames, currentContext, currentState)
    }
    const { uiSchema } = currentContext
    const uiContext = currentContext.uiContext || frames.getContext('uiContext')
    const resolved = this.schemaResolver.resolveSubschema(uiContext, uiSchema)
    currentContext = {
      ...currentContext,
      uiContext: resolved.context,
      uiSchema: resolved.schema,
    }
    return super.createChildFrame(frames, currentContext, currentState)
  }

}

import _ from 'lodash'
import * as Sentry from '@sentry/browser'

import { FormulaRegistry } from 'shared-libs/helpers/formulas'
import { AbstractSettings } from 'shared-libs/models/abstract-settings'
import { Query } from 'shared-libs/models/query'
import { initializeTimezone } from 'shared-libs/helpers/utils'
import { geoDistance } from 'shared-libs/helpers/formulas/modules/math'

import { getCurrentGeoPosition, qrOnboardingCode, stringToBoolean } from 'browser/app/utils/utils'
import { Logger } from 'browser/apis/logging'
import apis from 'browser/app/models/apis'
import { ApplicationBundle } from 'shared-libs/models/app-bundle'
import { requestFirebaseNotificationPermission } from '../../components/atomic-elements/atoms/notification/push-notification'
import { PlatformType } from 'shared-libs/models/types/storyboard/storyboard-plan'
import { FirebaseApp } from '../config/firebase'
import { Value, fetchAndActivate, getRemoteConfig, getValue, getAll } from 'firebase/remote-config'
import { getPerformance, FirebasePerformance } from '@firebase/performance'
import { getLocalStorageItems, putLocalStorageItem } from 'browser/contexts/local-storage/utils'
import { IFlagsmith } from 'react-native-flagsmith/types'
import { EntitySubscriptionManager } from '../utils/entity-subscription-manager'

const REMOTE_CONFIG_OVERRIDE_KEY = 'remote_config_overrides'

export class Settings extends AbstractSettings {
  private timezone: string
  private remoteConfig = getRemoteConfig(FirebaseApp)
  private remoteConfigOverrides = {}
  private performance = getPerformance(FirebaseApp)
  private flagsmithClient?: IFlagsmith

  constructor(timezone, platform: PlatformType) {
    super(apis)
    this.applicationBundle = new ApplicationBundle(apis)
    this.timezone = timezone
    this.platform = platform
    this.remoteConfig.settings.minimumFetchIntervalMillis = 1000
    apis.setSettings(this)
    this.performance.dataCollectionEnabled = true
    this.performance.instrumentationEnabled = false
  }

  public async initialize() {
    const [firm, user] = await this.fetchAppData()
    this.firm = firm
    this.user = user
    this.initializeAmplitude()
    this.initializeCustomFunctions()
    this.initializePlatformFunctions()
    this.initializeSentry()
    this.initializeTimeZone()

    EntitySubscriptionManager.userId = this.user.uniqueId

    void requestFirebaseNotificationPermission()
  }

  public fetchAppData() {
    return this.applicationBundle
      .initialize()
      .then((bundle) => this.applicationBundle.resolve(bundle))
      .then(() =>
        Promise.all([
          apis.getFirm(),
          apis.getUser(),
          this.fetchSettings(),
          this.fetchMappings(),
          this.fetchPortals(),
          this.fetchInboxToken(),
          this.fetchRemoteConfig(),
        ])
      )
  }

  // we need to reload settings after integrating with a third party,
  // need to make this public
  public fetchSettings() {
    const handleChange = (settings) => {
      this.settings = settings
    }
    const collection = new Query(this.apis)
      .setEntityType('/1.0/entities/metadata/settings.json')
      .setDebug({ context: 'model--Settings' })
      .getCollection()
      .addUpdateListener(handleChange)
    return collection.find().then(handleChange)
  }

  private fetchRemoteConfig() {
    const localStorage = getLocalStorageItems()
    const persistedOverrides = localStorage[REMOTE_CONFIG_OVERRIDE_KEY] || {}
    _.forEach(Object.keys(persistedOverrides), (key) => {
      this.remoteConfigOverrides[key] = this.primitiveToValue(persistedOverrides[key])
    })
    return fetchAndActivate(this.remoteConfig)
  }

  public fetchMappings() {
    const handleChange = (mappings) => {
      this.mappings = mappings
    }
    const collection = new Query(this.apis)
      .setEntityType('/1.0/entities/metadata/core_entity_mapping.json')
      .setDebug({ context: 'model--EntityMappings' })
      .getCollection()
      .addUpdateListener(handleChange)
    return collection.find().then(handleChange)
  }

  public fetchPortals() {
    const handleChange = (portals) => {
      this.portals = portals
    }
    const collection = new Query(this.apis)
      .setEntityType('/1.0/entities/metadata/core_portal.json')
      .setDebug({ context: 'model--Portals' })
      .getCollection()
      .addUpdateListener(handleChange)
    return collection.find().then(handleChange)
  }

  /**
   * Creates a firebase Value struct from a primitive.
   */
  private primitiveToValue(value?: null|string|number|boolean): Value {
    const trueValues = ['true', 'yes', 'y', '1', 't', 'on']
    if (_.isNil(value)) {
      return {
        asBoolean: () => false,
        asString: () => typeof value,
        asNumber: () => 0,
        getSource: () => 'static'
      }
    }
    return {
      asBoolean: () => stringToBoolean(value),
      asString: () => value.toString(),
      asNumber: () => parseFloat(value.toString()),
      getSource: () => 'static'
    }
  }

  /**
   * Fetches a remote config value from FlagSmith
   * @param name The name of the parameter to fetch
   * @param defaultValue Optional value to return if not found in config
   * @returns The config, which can be of any type
   */
  public getRemoteConfigValue(name: string, defaultValue?: any): any {
    // TODO: it looks like there isn't a remote config changed notifier for web?
    // see https://firebase.google.com/docs/remote-config/real-time?hl=en&authuser=0&platform=ios
    const remoteOverride = this.remoteConfigOverrides[name]
    if (!_.isEmpty(remoteOverride)) {
      if (typeof(remoteOverride.asString) === 'function') {
        return remoteOverride.asString()
      }
      return remoteOverride
    }
    const retValue = this.flagsmithClient.getValue(name)
    if (_.isEmpty(retValue)) {
      return defaultValue
    }
    return retValue
  }

  /**
   * Fetches a Remote Config from Firebase
   * @param name The name of the config
   * @param defaultValue The value to send if unset
   * @returns the value of the config, as a Firebase Value object.
   */
  public getLegacyRemoteConfigValue(name: string, defaultValue?: any): Value {
    // TODO: it looks like there isn't a remote config changed notifier for web?
    // see https://firebase.google.com/docs/remote-config/real-time?hl=en&authuser=0&platform=ios
    const remoteOverride = this.remoteConfigOverrides[name]
    if (!_.isEmpty(remoteOverride)) {
      if (typeof(remoteOverride.asString) === 'function') {
        return remoteOverride
      }
      return this.primitiveToValue(remoteOverride)
    }
    const retValue = getValue(this.remoteConfig, name)
    if (_.isEmpty(retValue?.asString())) {
      return this.primitiveToValue(defaultValue)
    }
    return retValue
  }

  public setRemoteConfigOverride(name: string, value: string): void {
    if (_.isEmpty(name)) {
      console.warn('Name must not be empty')
      return
    }
    const localStorage = getLocalStorageItems()
    const persistedOverrides = localStorage?.[REMOTE_CONFIG_OVERRIDE_KEY] || {}
    if (_.isNil(value)) {
      try {
        delete this.remoteConfigOverrides[name]
        delete persistedOverrides[name]
      } catch(e) {
        console.warn(e.message)
      }
    } else {
      this.remoteConfigOverrides[name] = value
      persistedOverrides[name] = value
    }
    putLocalStorageItem(REMOTE_CONFIG_OVERRIDE_KEY, persistedOverrides)
  }

  private getAllLegacyConfigs(): any {
    const allLegacyConfigs = getAll(this.remoteConfig)
    return _.reduce(Object.keys(allLegacyConfigs), (configs, key) => {
      configs[key] = allLegacyConfigs[key].asString()
      return configs
    }, {})
  }

  private getAllFlagsmithConfigs(): any {
    const allConfigs = this.flagsmithClient?.getAllFlags()
    return _.reduce(Object.keys(allConfigs || {}), (configs, key) => {
      if (allConfigs[key].enabled) {
        configs[key] = allConfigs[key].value
      }
      return configs
    }, {})
  }

  public getAllRemoteConfigs(): any {
    const allLegacyConfigs = this.getAllLegacyConfigs()
    const allConfigs = this.getAllFlagsmithConfigs()
    const mergedConfigs = { ...allLegacyConfigs, ...allConfigs }
    _.forEach(Object.keys(mergedConfigs), (key) => {
      const remoteOverride = this.remoteConfigOverrides[key]
      if (!_.isEmpty(remoteOverride)) {
        if (typeof(remoteOverride.asString) === 'function') {
          mergedConfigs[key] = remoteOverride.asString()
        } else {
          mergedConfigs[key] = remoteOverride
        }
      }
    })
    return mergedConfigs
  }

  public getPerformance(): FirebasePerformance {
    return this.performance
  }

  public getTimezone() {
    return this.timezone
  }

  public async fetchInboxToken() {
    const config = await apis.getInboxToken()
    this.inboxConfiguration = config
    return config
  }

  public updateFlagsmith(flagsmith: IFlagsmith) {
    this.flagsmithClient = flagsmith
  }

  private initializeAmplitude() {
    Logger.setUserId(this.user.uniqueId)

    try {
      const user = this.user
      const firm = this.firm

      const userId = _.get(user, 'uniqueId', '')
      const userName = _.get(user, 'owner.user.displayName', '')
      const userFirstName = _.get(user, 'person.firstName', '')
      const userLastName = _.get(user, 'person.lastName', '')
      const userEmails = _.join(_.map(_.get(user, 'person.emails'), 'value'), ',')
      const userPhones = _.join(_.map(_.get(user, 'person.phoneNumbers'), 'value.phone'), ',')

      const roles = _.filter(_.map(_.get(user, 'mixins.active'), 'displayName'), (name: string) => {
        return name.toLowerCase() !== 'entity'
      })
      const userRoles = _.join(roles, ',')

      const firmId = _.get(firm, 'uniqueId', '')
      const firmName = _.get(firm, 'displayName', '')

      Logger.setUserProperties({
        userId,
        userName,
        userFirstName,
        userLastName,
        userEmails,
        userPhones,
        userRoles,
        firmId,
        firmName,
        platform: this.platform,
      })
    } catch (ex) {
      console.log('Unable to set user properties for user ' + this.user.uniqueId)
    }
  }

  private initializeCustomFunctions() {
    // initialize user defined custom function()
    const generalSettings = this.getGeneralSettings()
    if (!generalSettings) {
      return
    }
    const customFunctions = generalSettings.get('generalSettings.customFunctions')
    _.forEach(customFunctions, ({ name, customFunction }) => {
      FormulaRegistry.registerFunctionFromString(name, customFunction)
    })
  }

  private initializePlatformFunctions() {
    FormulaRegistry.registerFunction('GEOPOSITION', () => {
      return getCurrentGeoPosition().then(
        (position: any) => {
          return {
            longitude: position.coords.longitude,
            latitude: position.coords.latitude,
          }
        },
        (error) => {
          return {}
        }
      )
    })

    FormulaRegistry.registerFunction('GEOFENCE', (...params) => {
      const fenceRadius = params[1]
      const startAddr = _.get(params, '[0].geolocation', params[0])

      return getCurrentGeoPosition().then(
        (currAddr: any) => {
          const distance = geoDistance(startAddr, {
            longitude: currAddr.coords.longitude,
            latitude: currAddr.coords.latitude,
          })
          return distance < fenceRadius
        },
        (error) => {
          // // if unable to get current address, should we pop up the message
          // ErrorModal.open({
          //   errorText: 'Error: ' + JSON.stringify(error),
          //   errorTitle: 'Unable to get GPS location',
          // })
        }
      )
    })

    FormulaRegistry.registerFunction('QR_USERINVITE', (invite) => {
      const companyCodes = _.get(this.firm, 'firm.companyCodes', [])
      const defaultCompanyCodes = _.filter(companyCodes, { isDefault: true })
      const companyCode = _.get(defaultCompanyCodes, '[0].code', '')

      const hostName = 'https://app.loaddocs.co/'
      const deepLink = new URL(hostName + 'register')

      deepLink.searchParams.append('companyCode', companyCode)
      deepLink.searchParams.append('firstName', _.get(invite, 'invite.firstName', ''))
      deepLink.searchParams.append('lastName', _.get(invite, 'invite.lastName', ''))
      deepLink.searchParams.append('email', _.get(invite, 'invite.email', ''))
      deepLink.searchParams.append('phoneNumber', _.get(invite, 'invite.phoneNumber.phone', ''))
      deepLink.searchParams.append('autoNavigate', 'true')

      return deepLink.toString()
    })

    FormulaRegistry.registerFunction('QR_ONBOARDING', (firm) => {
      return qrOnboardingCode(firm)
    })

    FormulaRegistry.registerFunction('GET_RECORD', (edgeOrId: { entityId: string} | string) => {
      const entityId = _.get(edgeOrId, 'entityId', edgeOrId)
      return apis.getStore().getRecord(entityId)
    })
  }

  private initializeSentry() {
    const { email } = this.user.person
    Sentry.setUser({
      email,
      id: this.user.uniqueId,
      username: email,
    })
  }

  private initializeTimeZone() {
    initializeTimezone(this.timezone)
  }
}

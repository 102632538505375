import Promise from 'bluebird'
import _ from 'lodash'

import { Formatter } from '../helpers/formatter'
import { JSONSchemaResolver } from '../resolvers/json-schema-resolver'
import { Query } from './query'

const ENTITY_SCHEMA_ID = '/1.0/entities/metadata/entity.json'

const GROUP_BY_ACTIVE_MIXINS_QUERY = {
  groups: [
    {
      path: 'mixins.active',
      type: 'edge',
    },
  ],
}

const DEFAULT_COLUMN_TEMPLATE = {
  isSortable: true,
  isVisible: true,
  width: 120,
}

const GROUP_OPTIONS_TEMPLATES = {}
const FILTER_OPTIONS_TEMPLATES = {}
const TABLE_COLUMN_TEMPLATE = {}

const registerColumnType = (type: string, value: any) => {
  TABLE_COLUMN_TEMPLATE[type] = value
}

const registerFilterType = (type: string, value: any) => {
  FILTER_OPTIONS_TEMPLATES[type] = value
}

const registerGroupType = (type: string, value: any) => {
  GROUP_OPTIONS_TEMPLATES[type] = value
}

///////////////////////////////////////////////////////////////////////////////
// Columns
///////////////////////////////////////////////////////////////////////////////
registerColumnType('string', DEFAULT_COLUMN_TEMPLATE)
registerColumnType('number', DEFAULT_COLUMN_TEMPLATE)
registerColumnType('integer', DEFAULT_COLUMN_TEMPLATE)
registerColumnType('boolean', { ...DEFAULT_COLUMN_TEMPLATE, format: { type: 'boolean' } })
registerColumnType('/1.0/entities/metadata/entity.json#/definitions/edge', DEFAULT_COLUMN_TEMPLATE)
registerColumnType('/1.0/entities/metadata/entity.json#/definitions/fileSet', (path) => ({
  ...DEFAULT_COLUMN_TEMPLATE,
  cellComponent: {
    type: 'ui:table:filesCell',
    valuePath: path
  }
}))
registerColumnType('/1.0/entities/metadata/dispatchOrder.json#/definitions/trailerType', DEFAULT_COLUMN_TEMPLATE)
registerColumnType('/1.0/entities/metadata/entity.json#/definitions/time', { ...DEFAULT_COLUMN_TEMPLATE, width: 130 })

///////////////////////////////////////////////////////////////////////////////
// Filters
///////////////////////////////////////////////////////////////////////////////
registerFilterType('string', { type: 'match' })
registerFilterType('string:date', { type: 'range' })
registerFilterType('string:date-time', { type: 'range' })
registerFilterType('number', { type: 'match' })
registerFilterType('integer', { type: 'match' })
registerFilterType('boolean', { type: 'match' })
registerFilterType('/1.0/entities/metadata/entity.json#/definitions/edge', { type: 'matchEdge' })

///////////////////////////////////////////////////////////////////////////////
// Groups
///////////////////////////////////////////////////////////////////////////////
registerGroupType('string', { type: 'field' })
registerGroupType('/1.0/entities/metadata/entity.json#/definitions/edge', { type: 'edge' })

export class QueryOptionsGenerator {

  public static getValidSchemas(apis, entitySchema) {
    const store = apis.getStore()
    const collection = new Query(apis)
      .setEntityType(entitySchema.id)
      .setGroups(GROUP_BY_ACTIVE_MIXINS_QUERY.groups)
      .setShouldIncludeLeafEntities(false)
      .setDebug({ context: 'model--FilterQueryOptions' })
      .getCollection()
    return new Promise((resolve, reject, onCancel) => {
      onCancel(() => collection.cancel())
      collection.find().then((content: any[]) => {
        // sort derived entity schemas by displayName
        const schemaIds = _.map(content, (option: any) => option.data.entityId)
        return store.findRecords(schemaIds)
      }).then((validSchemas) => {
        const entitySchema = store.getRecord(ENTITY_SCHEMA_ID)
        validSchemas.push(entitySchema)
        _.sortBy(validSchemas, 'displayName')
        resolve(validSchemas)
      })
    }).finally(() => {
      collection.dispose()
    })
  }

  public static getFiltersFromGroups(groupSpecs, groups) {
    return _.map(groups, (group: any, index) => {
      const { label, path, type } = groupSpecs[index]
      const value = group.data
      if (type === 'edge') {
        return { label, path, type: 'matchEdge', value }
      } else if (type === 'lane') {
        return { label, path, type: 'matchLane', value }
      }
      return { label, path, type: 'match', value }
    })
  }

  public static getColumnOptions(columns, validSchemas) {
    const options = []
    // We want to ignore this column in the option generator
    // https://app.asana.com/0/389978771254907/512436922383045
    const optionFilter = (propertySchema, path) => {
      return _.get(propertySchema, 'isColumnAvailable', true) &&
          !_.find(columns, { path }) && !_.find(options, { path })
    }
    _.forEach(validSchemas, (entitySchema) => {
      const entitySchemaOptions = this.getValidEntityOptions(
        entitySchema, TABLE_COLUMN_TEMPLATE, optionFilter)
      options.push(...entitySchemaOptions)
    })
    return options
  }

  public static getFilterOptions(validSchemas) {
    const options = []
    const optionFilter = (propertySchema, path) => {
      return propertySchema.isFilterable && !_.find(options, { path })
    }
    _.forEach(validSchemas, (entitySchema) => {
      const entitySchemaOptions = this.getValidEntityOptions(
        entitySchema, FILTER_OPTIONS_TEMPLATES, optionFilter)
      options.push(...entitySchemaOptions)
    })
    return options
  }

  public static getGroupOptions(groups, validSchemas) {
    const options = []
    const optionFilter = (propertySchema, path) => {
      return !_.find(groups, { path }) && !_.find(options, { path })
    }
    _.forEach(validSchemas, (entitySchema) => {
      const entitySchemaOptions = this.getValidEntityOptions(
        entitySchema, GROUP_OPTIONS_TEMPLATES, optionFilter)
      options.push(...entitySchemaOptions)
    })
    return options
  }

  private static getValidEntityOptions(entitySchema, optionTemplates, isValidOption) {
    const namespace = entitySchema.id === ENTITY_SCHEMA_ID ? null :
        entitySchema.get('metadata.namespace')
    const properties = entitySchema.id === ENTITY_SCHEMA_ID ? entitySchema.properties :
        entitySchema.get(`properties.${namespace}.properties`)
    const pathParts = namespace ? [namespace] : []
    return this.getValidOptions(entitySchema, optionTemplates, isValidOption, namespace, properties, pathParts, 0)
  }

  private static getValidOptions(entitySchema, optionTemplates, isValidOption, namespace, schema, pathParts, depth) {
    const options = []
    _.forEach(schema, (propertySchema, key) => {
      const pathArr = _.clone(pathParts)
      const path = [...pathArr, key].join('.')
      const properties = propertySchema.properties
      if (properties) {
        // recurse over subschema that have 'properties'
        pathArr.push(key)
        const nestedOptions = this.getValidOptions(entitySchema, optionTemplates, isValidOption,
          namespace, properties, pathArr, depth + 1)
        options.push(...nestedOptions)
      } else {
        const optionTemplate = this.getOptionTemplate(optionTemplates, path, propertySchema)
        if (!optionTemplate) { return }
        if (isValidOption(propertySchema, path)) {
          const entityType = entitySchema.id
          const label = propertySchema.label || Formatter.camelCaseToWord(key)
          options.push({ ...optionTemplate, entityType, label, path })
        }
      }
    })
    return options
  }

  private static getOptionTemplate(optionTemplates, path: string, schema) {
    let key = schema.type || schema[JSONSchemaResolver.REF_KEY]
    if (schema.type === 'string' && !_.isNil(schema.format)) {
      key = `${schema.type}:${schema.format}`
    }
    const optionTemplate = optionTemplates[key]
    if (!optionTemplate) { return }
    if (_.isFunction(optionTemplate)) {
      return optionTemplate(path, schema)
    }
    return optionTemplate
  }
}

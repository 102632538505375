// from https://raw.githubusercontent.com/ajv-validator/ajv-keywords/master/keywords/uniqueItemProperties.js
// This is a modified copy that sets .errors on the validation function
// to indicate where the duplicate values occur, so that we can plug into error
// highlighting in our UI components like the rest of our ajv validation errors do.
'use strict';

// eslint-disable-next-line  @typescript-eslint/no-var-requires
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));
var _ = require('lodash');
var SCALAR_TYPES = ['number', 'integer', 'string', 'boolean', 'null'];
module.exports = function defFunc(ajv) {
  defFunc.definition = {
    type: 'array',
    compile: function compile(keys, parentSchema, it) {
      var equal = it.util.equal;
      var scalar = getScalarKeys(keys, parentSchema);
      return function validate(data) {
        if (data.length > 1) {
          for (var k = 0; k < keys.length; k++) {
            var key = keys[k];
            var i = void 0;
            if (scalar[k]) {
              var hash = {};
              for (i = 0; i < data.length; i++) {
                if (!data[i] || (0, _typeof2["default"])(data[i]) != 'object') continue;
                var prop = data[i][key];
                if (prop && (0, _typeof2["default"])(prop) == 'object') continue;
                if (typeof prop == 'string') prop = '"' + prop;
                if (hash[prop]) {
                  validate.errors = makeErrors(validate.errors, key, i, it);
                }
                hash[prop] = true;
              }
            } else {
              for (i = 0; i < data.length; i++) {
                if (!data[i] || (0, _typeof2["default"])(data[i]) != 'object') continue;
                for (var j = i + 1; j < data.length; j++) {
                  if (data[j] && (0, _typeof2["default"])(data[j]) == 'object' && equal(data[i][key], data[j][key])) {
                    validate.errors = makeErrors(validate.errors, key, j, it);
                  }
                }
              }
            }
          }
        }
        return !validate.errors || !validate.errors.length;
      };
    },
    metaSchema: {
      type: 'array',
      items: {
        type: 'string'
      }
    }
  };
  ajv.addKeyword('uniqueItemProperties', defFunc.definition);
  return ajv;
};
function getScalarKeys(keys, schema) {
  return keys.map(function (key) {
    var properties = schema.items && schema.items.properties;
    var propType = properties && properties[key] && properties[key].type;
    return Array.isArray(propType) ? propType.indexOf('object') < 0 && propType.indexOf('array') < 0 : SCALAR_TYPES.indexOf(propType) >= 0;
  });
}
function makeErrors(errors, key, idx, it) {
  var ret = errors || [];
  var keyword = 'uniqueItemProperties';
  // remove leading jsonpointer slash
  key = key.replace(/^\//, '');
  // replace all jsonpointer slashes
  key = key.replace(/\//g, '.');
  // remove surrounding quotes
  var errorPath = (it.errorPath || "").replace(/^['"]|['"]$/g, '');
  var message = _.get(it, 'schema.errorMessage.uniqueItemProperties', 'should be unique');
  ret.push({
    keyword: keyword,
    params: {
      keyword: keyword
    },
    message: message,
    dataPath: "".concat(errorPath, ".").concat(idx, ".").concat(key)
  });
  return ret;
}
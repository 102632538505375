import _ from 'lodash'
import Platform from 'platform'
import queryString from 'query-string'
import React from 'react'
import { withRouter } from 'react-router-dom'

import { Formatter } from 'shared-libs/helpers/formatter'
import { isPhoneValid } from 'shared-libs/helpers/utils'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { Head } from 'browser/components/atomic-elements/atoms/head/head'
import {
  AuthenticationPage,
} from 'browser/components/atomic-elements/organisms/authentication-page/authentication-page'
import { VerificationBlock } from 'browser/components/atomic-elements/organisms/verification-block/verification-block'
import { browserHistory } from 'browser/history'
import { ComponentsContext } from 'browser/contexts/components/components-context'
import { PlatformType } from 'shared-libs/models/types/storyboard/storyboard-plan'

const PENDING_USER_ID_PARAM_NAME = 'pendingUserId'

interface IVerifyProps extends IBaseProps {
  location: any
}

interface IVerifyState {
  code?: string
  identity?: string
  /* a specialized "next route" only for the /verify screen to consume */
  nextVerificationRoute?: string
  /* the original url to restore after auth is complete */
  nextRoute?: string
  /**
   * Whether to handle 2FA on the user's behalf since the user is
   * "preregistered" and we want to get them into the deeplink as smoothly as
   * possible.
   */
  shouldAttemptPreauthVerification?: boolean
}

@withRouter
export class Verify extends React.Component<IVerifyProps, IVerifyState> {
  constructor(props: IVerifyProps) {
    super(props)
    const { location } = props
    this.state = {}
    if (location.search) {
      const search = queryString.parse(location.search)
      const { code, [PENDING_USER_ID_PARAM_NAME]: pendingUserId } = search
      const identity = search.identity || _.get(location, 'state.identity')
      const nextVerificationRoute = search.nextVerificationRoute || _.get(location, 'state.nextVerificationRoute')
      const nextRoute = search.nexRoute || _.get(location, 'state.nextRoute')
      const shouldAttemptPreauthVerification = _.size(code) > 6 && !_.isEmpty(pendingUserId)
      _.assign(this.state, { code, identity, nextVerificationRoute, nextRoute, shouldAttemptPreauthVerification })
    } else {
      const { identity, nextRoute } = _.get(location, 'state', {})
      _.assign(this.state, { identity, nextRoute })
    }
  }

  public componentDidMount(): void {
    const { shouldAttemptPreauthVerification, code, identity } = this.state

    if (shouldAttemptPreauthVerification) {
      apis
        .postLogin(identity, code)
        .then(() => {
          this.handleSuccess()
        })
        .catch(() => {
          // send a new code if the preauth verification fails
          apis.sendVerificationCode(identity).finally(() => {
            this.setState({
              shouldAttemptPreauthVerification: false,
              code: undefined,
            })
          })
        })
    }
  }

  public render() {
    return (
      <ComponentsContext.Consumer>
        {({ platform }) => (this.handleRedirect(platform) ? null : this.renderContent())}
      </ComponentsContext.Consumer>
    )
  }

  private handleRedirect(platform: PlatformType): boolean {
    const { code } = this.state
    if (platform === PlatformType.WEB) {
      if (Platform.os.family === 'Android') {
        // tslint:disable-next-line:max-line-length
        window.location.replace(`https://play.google.com/store/apps/details?id=com.convoy.loaddoc&referrer=utm_source%3D${code}`)
        return true
      } else if (Platform.os.family === 'iOS') {
        /*
        * For iOS, unlike Google PlayStore, the App Store will not forward any data
        * to our app after the installation, branch.io is used instead.
        * The web will ask the server to generate the branch link with the given parameters and open the branch link.
        * This path is basically the same as invite via text message.
        */
        const url = apis.getEmptyDynamicLinkInvite()
        window.location.href = url
        return true
      }
    }
    return false
  }

  private renderContent() {
    const { identity, shouldAttemptPreauthVerification } = this.state
    const formattedIdentity = isPhoneValid(identity) ?
      Formatter.formatPhoneNumber(identity) : identity

    return (
      <AuthenticationPage
        className='mw5-m mw5-l'
        bodyClassName='c-onboarding-body'
      >
        <Head
          title={`Verify ${formattedIdentity}`}
        />
        {shouldAttemptPreauthVerification
          ? this.renderPreauthenticatedContent()
          : this.renderVerificationBlockContent(formattedIdentity)}
      </AuthenticationPage>
    )
  }

  private renderPreauthenticatedContent() {
    return (
      <div className="w-100 mb3">
        <h3 className="f3 b lh-title mt4 mb2 tc">Verifying</h3>
        <p className="lh-copy tc mb3 mt1 measure">
          Verifying access, please wait...
        </p>
      </div>
    )
  }

  private renderVerificationBlockContent(formattedIdentiy: string) {
    const { code, identity } = this.state
    const identityType = isPhoneValid(identity) ? 'mobile number' : 'email'
    return (
      <>
        <div className="w-100 mb3">
          <h3 className="f3 b lh-title mt4 mb2 tc">Enter Verification Code</h3>
          <p className="lh-copy tc mb3 mt1 measure">
            Let us know this {identityType} belongs to you. We{"'"}ve sent you a six-digit
            verification code to <span className="b">{formattedIdentiy}</span>. It will expire
            shortly, so enter your code soon.
          </p>
          <VerificationBlock
            identity={identity}
            onSubmit={this.handleSubmitCode}
            onSuccess={this.handleSuccess}
            value={code}
          />
        </div>
        <Footer
          className="c-footer--transparent c-footer--horizontalPadding"
          cancelButtonText="Back"
          onCancelButtonClick={browserHistory.goBack}
        />
      </>
    )
  }

  private handleSubmitCode = (code) => {
    const { identity } = this.state
    return apis.postLogin(identity, code)
  }

  private handleSuccess = () => {
    const { identity } = this.state
    const nextRoute = this.cleanNextRoute(this.state.nextRoute)
    const nextVerificationRoute = this.cleanNextRoute(this.state.nextVerificationRoute)
    const targetRoute = nextVerificationRoute || nextRoute
    if (targetRoute) {
      browserHistory.push(targetRoute, { identity, nextRoute })
    } else {
      browserHistory.push('/redirect')
    }
  }

  /* partialUrl is a react-router friendly path + querystring, like `/deeplink?foo=bar` */
  private cleanNextRoute(partialUrl: string | undefined): string | undefined {
    if (!partialUrl) {
      return
    }
    const [path, queryString] = partialUrl.split('?')
    const searchParams = new URLSearchParams(queryString)
    const paramsToRemove = ['code', 'pendingUserId']
    paramsToRemove.forEach((param) => searchParams.delete(param))
    const newQueryString = searchParams.toString()
    return newQueryString ? `${path}?${newQueryString}` : path
  }
}

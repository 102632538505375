export const DEFAULT_INTERSTITIAL_SYNC_SCHEMA = {
  type: 'ui:uploadProgress',
  entityId: {
    type: 'expression',
    value: 'executionEntityId',
  },
  viewPassthrough: {
    type: 'ui:view',
    style: {
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
      alignSelf: 'center',
      width: '70%',
    },
    children: [
      {
        type: 'ui:helpBlock',
        style: {
          color: '#4F4F4F',
          fontSize: 20,
          fontWeight: '500',
          lineHeight: 10,
          marginLeft: 5,
          marginRight: 5,
          marginTop: -3,
        },
        helpText: {
          type: 'expression',
          value:
            "'Uploading ... ' + progressDetails.numCompletedJobs + ' / ' + progressDetails.numJobs",
        },
      },
      {
        type: 'ui:progressBar',
        mapStateToProps: {
          progress: 'progress',
        },
      },
      {
        type: 'ui:conditional',
        mapStateToProps: {
          actionHooks: 'actionHooks',
        },
        conditionals: [
          {
            type: 'ui:view',
            action: 'nextLoadingSpinner()',
            test: '!progressDetails.isCompleted',
          },
          {
            type: 'ui:view',
            action: 'nextEnable(); goNext()',
            test: 'progressDetails.isCompleted',
          },
        ],
      },
    ],
  },
}

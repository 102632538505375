import React from 'react'
import _ from 'lodash'

interface ISafeViewProps {
  /**
   * Resets the error state of the component when its value changes.
   */
  errorInvalidationKey?: any

  /**
   * Optional function for checking whether the component should reset its error
   * state.
   */
  shouldInvalidateError?: (props: ISafeViewProps, prevProps: ISafeViewProps) => boolean

  /**
   * Optional override for rendering the error state.
   */
  renderError?: (error: Error) => any
}

interface ISafeViewState {
  error?: Error
}

const DEFAULT_ERROR_INVALIDATOR = (props: ISafeViewProps, prevProps: ISafeViewProps): boolean =>
  props.errorInvalidationKey !== prevProps.errorInvalidationKey

export class SafeView extends React.Component<ISafeViewProps, ISafeViewState> {
  static defaultProps: ISafeViewProps = {
    shouldInvalidateError: DEFAULT_ERROR_INVALIDATOR,
  }

  constructor(props: ISafeViewProps) {
    super(props)
    this.state = {}
  }

  public componentDidCatch(error: Error): void {
    this.injectError(error)
  }

  public componentDidUpdate(prevProps: Readonly<ISafeViewProps>): void {
    const { error } = this.state
    const { shouldInvalidateError } = this.props

    if (error && shouldInvalidateError(this.props, prevProps)) {
      this.setState({
        error: undefined,
      })
    }
  }

  public render() {
    const { renderError } = this.props
    const { error } = this.state
    if (error) {
      return renderError?.(error)
    }

    return this.props.children
  }

  /**
   * Provides a manual way to set the error state. On mobile in dev mode, when
   * the renderer throws, a RedBox error would otherwise be displayed regardless
   * of whether it's caught in a componentDidCatch.
   */
  public injectError(error) {
    this.setState({ error })
  }
}

import React from 'react'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import { FramesManager } from 'shared-libs/components/view/frames-manager'
import {
  FormulaComponentRegistry,
  FormulaComponentInfo,
} from 'shared-libs/components/entity/formula-component-registry'

import ComponentsMap from 'browser/components'
import { IInputProps, Input } from 'browser/components/atomic-elements/atoms/input/input'

/**
 * @uiComponent
 */
interface IFormulaInputProps extends IInputProps {
  defaultValue?: any
  formula: string
  frames: FramesManager
  inputComponent: any
  isComputedProperty?: boolean
  computedName?: string
  numDecimalPlaces?: number
  entity?: any
  onChange?: (value: any) => void
  formulaRegistry: FormulaComponentRegistry
  forceUpdate?: () => void
  isSelectedStyle?: boolean
  isVisible?: boolean
}

export class FormulaInput extends React.Component<IFormulaInputProps, any> {
  public static defaultProps: Partial<IFormulaInputProps> = {
    inputComponent: Input,
    isSelectedStyle: false,
    isVisible: true,
  }

  private valueRef = React.createRef() as React.MutableRefObject<any>
  private mountID?: string

  public componentDidMount() {
    const { formulaRegistry, frames } = this.props

    this.mountID = uuidv4()
    formulaRegistry.register(new FormulaComponentInfo(this.mountID!, this.valueRef, frames))
  }

  public componentWillUnmount() {
    const { formulaRegistry } = this.props
    formulaRegistry.unregister(this.mountID!)
  }

  public componentDidUpdate() {
    const { formulaRegistry, frames } = this.props
    if (!formulaRegistry) return

    formulaRegistry.update(new FormulaComponentInfo(this.mountID!, this.valueRef, frames))
  }

  public render(): React.ReactElement<IFormulaInputProps> {
    const { isSelectedStyle, isVisible, readOnly } = this.props
    const inputComponent = this.getInputComponent()
    const displayValue = this.getDisplayValue()

    if (!isVisible) {
      return null
    }

    // we need to disable the formula input because formula is not editable
    return React.createElement<IFormulaInputProps, any>(inputComponent, {
      ...this.props,
      isDisabled: readOnly === undefined ? true : false,
      isSelectedStyle: isSelectedStyle && displayValue,
      value: displayValue,
    })
  }

  private getDisplayValue() {
    const { numDecimalPlaces } = this.props
    const value = !_.isNil(this.valueRef.current) ? this.valueRef.current : undefined
    if (numDecimalPlaces && !_.isNil(value)) {
      return _.round(value, numDecimalPlaces).toFixed(numDecimalPlaces)
    }
    return value
  }

  private getInputComponent() {
    const { inputComponent } = this.props
    if (_.isString(inputComponent)) {
      return ComponentsMap[inputComponent]
    }
    return inputComponent
  }
}

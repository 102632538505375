import _ from 'lodash'

import { AbstractTriggers } from './abstract-triggers'
import { EntityType } from './entity-type'
import { Entity } from '../entity'
import { convertTimezone } from '../../helpers/utils'

const RATE_PER_UNIT = 'Rate per Unit'

export class DispatchOrder extends EntityType implements AbstractTriggers {

  // NOTE: is this trigger defunct? leaving silentUpdate unimplemented during
  // the formula field Stage 1 refactor.
  // (https://withvector.atlassian.net/browse/VD-3665)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public beforeChangeTrigger(entity: Entity, path: any, oldValue: any, newValue: any, silentUpdate = false) {
    this.resetCustomerCarrierLocationContacts(entity, path)
    this.resetDrivers(entity, path)
    this.syncCargos(entity)
    this.updateStopsTimeWindowTimezone(entity, path, newValue)
  }

  public validateTrigger(entity: Entity, errors: any[]) {
    this.validateAccountingItems(entity, ['dispatchOrder', 'expenseItems'], errors)
    this.validateAccountingItems(entity, ['dispatchOrder', 'revenueItems'], errors)
    // this.validateStops(entity, errors)
    // this.validateTrailerType(entity, errors)
  }

  /////////////////////////////////////////////////////////////////////////////
  // Triggers
  /////////////////////////////////////////////////////////////////////////////

  private resetCustomerCarrierLocationContacts(entity, path) {
    const pathString = path.join('.')
    if (pathString === 'brokerOrder.customer') {
      return entity.set('brokerOrder.customerContact', undefined)
    } else if (pathString === 'brokerOrder.carrier') {
      return entity.set('brokerOrder.carrierContact', undefined)
    } else if (path.length === 4 && path[0] === 'dispatchOrder' &&
      path[1] === 'stops' && path[3] === 'location') {
      const contactPath = path.slice(0, 3).concat(['contact'])
      entity.set(contactPath, undefined)
    }
  }

  private resetDrivers(entity, path) {
    const pathString = path.join('.')
    if (pathString === 'brokerOrder.carrier') {
      return entity.set('brokerOrder.driver', undefined)
    }
  }

  private syncCargos(entity) {
    const loadType = entity.get('dispatchOrder.loadType')
    const cargos = entity.get('dispatchOrder.cargos')
    const stops = entity.get('dispatchOrder.stops')
    // if load is multistop, the stopCargoTasks are filled in, so we can return
    // We populate the cargos in the component using the stopCargoTasks
    // TODO(Peter): do the stopCargoTasks cargos sync here
    if (loadType === 'Multi-stop Truck Load') {
      return
    }
    // otherwise, the stopCargoTasks needs to be infer from the cargos
    _.forEach(stops, (stop, stopIndex: number) => {
      // _.map creates [] if cargos is not defined, which causes a dirty change
      // on the order entity. Avoid creating extraneous dirty changes whenever
      // possible, because this gives user illusion that _they_ made a change,
      // and they will be prompted with confirmation when closing an empty new doc.
      // Ideally, if they made 0 changes, they shouldn't be prompted.
      if (!cargos) return

      stop.cargos = _.map(cargos, (cargo: any) => ({
        cargo: {
          arrayPath: 'properties.dispatchOrder.properties.cargos',
          itemId: cargo.uniqueId
        },
        quantity: cargo.quantity,
        type: stopIndex === 0 ? 'Pick Up' : 'Drop Off',
        value: cargo.value,
        weight: cargo.weight,
      }))
    })
  }

  private updateStopsTimeWindowTimezone(entity, path, location) {
    if (_.isArray(path)) { path = path.join('.') }
    const pathRegex = new RegExp(/dispatchOrder.stops.([\d]+).location/)
    if (!location || !pathRegex.test(path)) {
      return
    }
    const matches = pathRegex.exec(path)
    const stopIndex = matches[1]
    const stop = entity.get(`dispatchOrder.stops.${stopIndex}`)
    const { timeWindow } = stop
    const timezoneId = _.get(location, ['denormalizedProperties', 'location.address', 'timezoneId'])
    const start = _.get(timeWindow, 'start')
    const end = _.get(timeWindow, 'end')
    if (timezoneId && start) {
      const dateTime = convertTimezone(start.dateTime, start.timezone, timezoneId)
      timeWindow.start = { dateTime, timezone: timezoneId }
    }
    if (timezoneId && end) {
      const dateTime = convertTimezone(end.dateTime, end.timezone, timezoneId)
      timeWindow.end = { dateTime, timezone: timezoneId }
    }
  }

  private validateAccountingItems(entity, path, errors) {
    const items = entity.get(path)
    _.forEach(items, (item, index) => {
      if (item.ratingMethod === RATE_PER_UNIT) {
        if (_.isNil(item.quantity)) {
          errors.push({
            path: path.concat([index, 'quantity']),
            errors: ['is required']
          })
        }
        if (_.isNil(item.ratePerUnit)) {
          errors.push({
            path: path.concat([index, 'ratePerUnit']),
            errors: ['is required']
          })
        }
      }
    })
  }

  private validateStops(entity, errors) {
    const cargos = entity.get('dispatchOrder.cargos')
    const stops = entity.get('dispatchOrder.stops')
    _.forEach(stops, (stop, stopIndex) => {
      // validate stop tasks
      _.forEach(stop.cargos, (stopCargo, stopCargoIndex) => {
        const cargo: any = _.find(cargos, { uniqueId: stopCargo.cargoId })
        if (cargo && !cargo.description) {
          errors.push({
            path: ['dispatchOrder', 'stops', stopIndex, 'cargos', stopCargoIndex, 'description'],
            errors: ['is required']
          })
        }
      })
      // validate timeWindow for locations
      const { location } = stop
      const schedulingPolicy = _.get(location,
        ['denormalizedProperties', 'location.schedulingPolicy'])
      const requiresAppointment = schedulingPolicy === 'Appointment Required'
      if (requiresAppointment && !stop.timeWindow) {
        errors.push({
          path: ['dispatchOrder', 'stops', stopIndex, 'timeWindow'],
          errors: ['Location selected requires appointment']
        })
      }
    })
  }

  private validateTrailerType(entity, errors) {
    const trailerType = entity.get('dispatchOrder.trailerType')
    const temperatureRange = entity.get('dispatchOrder.temperatureRange')
    if (trailerType === 'Reefer') {
      if (_.isNil(_.get(temperatureRange, 'min'))) {
        errors.push({
          path: ['dispatchOrder', 'temperatureRange', 'min'],
          errors: ['is required']
        })
      }
      if (_.isNil(_.get(temperatureRange, 'max'))) {
        errors.push({
          path: ['dispatchOrder', 'temperatureRange', 'max'],
          errors: ['is required']
        })
      }
    }
  }
}

import classNames from 'classnames'
import _ from 'lodash'
import Platform from 'platform'
import queryString from 'query-string'
import React from 'react'
import { Link } from 'react-router-dom'
import { Classes } from '@blueprintjs/core'

import apis from 'browser/app/models/apis'
import 'browser/app/pages/onboarding/register/_register.scss'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { ErrorWrapper } from 'browser/components/atomic-elements/atoms/error-wrapper/error-wrapper'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { Head } from 'browser/components/atomic-elements/atoms/head/head'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
// tslint:disable-next-line:max-line-length
import { AuthenticationPage } from 'browser/components/atomic-elements/organisms/authentication-page/authentication-page'
import { browserHistory } from 'browser/history'
import { getUserSchema } from 'shared-libs/models/client/user'
import { isMobilePlatform, handleMobileDeferredDeepLink } from 'browser/app/utils/utils'

const FIRSTNAME_PARAM_NAME = 'firstName'
const LASTNAME_PARAM_NAME = 'lastName'
const PENDING_USER_ID_PARAM_NAME = 'pendingUserId'

interface IRegisterProps {
  location: any
}

interface IRegisterState {
  firmName: string
  inviteId?: string
  accessCode?: string
  isPortalInvite?: boolean
  isIdentityDisabled: boolean
  isLoading: boolean
  userEntity: any
  email: string
  phoneNumber: string
  firstName: string
  firstNameError: string
  lastName: string
  lastNameError: string
  password: string
  passwordError: string
  pendingUserId?: string
}

export class Register extends React.Component<IRegisterProps, IRegisterState> {
  private passwordInput: any

  constructor(props: IRegisterProps) {
    super(props)
    // TODO(Peter): figure out how to do client model correctly
    const store = apis.getStore()
    const UserSchema = getUserSchema(apis)
    UserSchema.registerAllProperties()
    store.cacheRecord(UserSchema)
    const userEntity: any = store.createRecord(UserSchema)
    const { location } = this.props
    const companyCode = _.get(location, 'state.companyCode', null)
    const firmName = _.get(location, 'state.firm.displayName', null)
    const invite = _.get(location, 'state.invite', null)
    const accessCode = _.get(location, 'state.accessCode', null)
    const parsedQueryString = queryString.parse(window.location.search)
    const { [PENDING_USER_ID_PARAM_NAME]: parsedPendingUserId } = parsedQueryString
    
    if (!invite && (!companyCode || !firmName)) {
      browserHistory.push({
        pathname: '/company-code',
        search: window.location.search,
        state: {
          nextRoute: _.get(location, 'state.nextRoute'),
        },
      })
    }

    if (invite) {
      const { carrierPortalInvite } = invite
      const { email, firstName, lastName } = invite.invite
      let { phoneNumber } = invite.invite
      if (_.isEmpty(phoneNumber) || _.isNull(phoneNumber)) {
        phoneNumber = _.get(location, 'state.phoneNumber', null)
      }
      this.state = {
        email,
        firmName,
        firstName,
        firstNameError: null,
        inviteId: invite.uniqueId,
        accessCode,
        isIdentityDisabled: !!userEntity.user.identity,
        isLoading: false,
        isPortalInvite: !_.isEmpty(carrierPortalInvite),
        lastName,
        lastNameError: null,
        password: null,
        passwordError: null,
        phoneNumber,
        userEntity,
        pendingUserId: parsedPendingUserId,
      }
    } else {
      const { [FIRSTNAME_PARAM_NAME]: parsedFirstName, [LASTNAME_PARAM_NAME]: parsedLastName } =
        parsedQueryString
      const prefilledFirstName = parsedFirstName ? decodeURIComponent(parsedFirstName) : undefined
      const prefilledLastName = parsedLastName ? decodeURIComponent(parsedLastName) : undefined
      const firstName = _.get(location, 'state.firstName', prefilledFirstName)
      const lastName = _.get(location, 'state.lastName', prefilledLastName)
      const email = _.get(location, 'state.email', null)
      const phoneNumber = _.get(location, 'state.phoneNumber', null)
      this.state = {
        email,
        firmName,
        firstName,
        firstNameError: null,
        accessCode,
        isIdentityDisabled: false,
        isLoading: false,
        isPortalInvite: false,
        lastName,
        lastNameError: null,
        password: null,
        passwordError: null,
        phoneNumber,
        userEntity,
        pendingUserId: parsedPendingUserId,
      }
    }
  }

  public componentDidMount() {
    const { pendingUserId } = this.state
    const passwordless = !_.isEmpty(pendingUserId)

    const { location } = this.props
    const invite = _.get(location, 'state.invite', null)
    if (invite && !passwordless) {
      this.passwordInput.getElement().focus()
    }
  }

  public render() {
    const {
      firmName,
      isLoading,
      firstName,
      firstNameError,
      lastName,
      lastNameError,
      password,
      passwordError,
    } = this.state
    const productName = apis.getProductName()
    const { location } = this.props
    const { pendingUserId } = this.state

    const passwordless = !_.isEmpty(pendingUserId)

    // if invite is portal invite, then display the browser version of the registration
    if (isMobilePlatform()) {
      // keep the existing way to handling mobile invite link
      if (this.handleMobileInviteLink() || handleMobileDeferredDeepLink(location)) {
        return <div/>
      }
    }

    return (
      <AuthenticationPage className='mw5-m mw5-l' bodyClassName='c-onboarding-body'>
        <Head title='Register' />
        <div className='ph4'>
          <h3 className='f3 b lh-title mt4 mb2 tc'>Let’s Create Your Account</h3>
          <div className='lh-copy mb4 mt1 tc'>{firmName ? `Welcome to ${firmName}` : `Welcome to Vector!`}</div>
          <div className='row'>
            <div className='col-xs-6'>
              <ErrorWrapper className='pb2' error={firstNameError}>
                <InputField
                  className={classNames('c-formGroup--inputHasBorder', {
                    'u-bumperBottom': !firstNameError
                  })}
                  errorText={firstNameError}
                  isHorizontalLayout={false}
                  label='First Name'
                  labelProps={{ className: 'c-label--heading' }}
                  onChange={this.handleFirstNameChange}
                  size='lg'
                  value={firstName}
                />
              </ErrorWrapper>
            </div>
            <div className='col-xs-6'>
              <ErrorWrapper className='pb2' error={lastNameError}>
                <InputField
                  className={classNames('c-formGroup--inputHasBorder', {
                    'u-bumperBottom': !lastNameError
                  })}
                  errorText={lastNameError}
                  isHorizontalLayout={false}
                  label='Last Name'
                  labelProps={{ className: 'c-label--heading' }}
                  onChange={this.handleLastNameChange}
                  size='lg'
                  value={lastName}
                />
              </ErrorWrapper>
            </div>
          </div>
          {!passwordless && (
            <ErrorWrapper className='pb4' error={passwordError}>
              <InputField
                className={classNames('c-passwordField c-formGroup--inputHasBorder', {
                  'u-bumperBottom': !passwordError
                })}
                errorText={passwordError}
                helpText={passwordError ? null : 'Must be at least 6 characters'}
                inputType='password'
                isHorizontalLayout={false}
                label='Password'
                labelProps={{ className: 'c-label--heading' }}
                onChange={this.handlePasswordChange}
                ref={(ref) => {
                  this.passwordInput = ref
                }}
                size='lg'
                value={password}
              />
            </ErrorWrapper>
          )}
          <Button
            buttonText='Continue'
            className={classNames(Classes.INTENT_PRIMARY, Classes.FILL)}
            isDisabled={false}
            isLoading={isLoading}
            onClick={this.handleRegister}
            size='large'
          />
          <div className='u-textCenter mt2 mb4'>
            <HelpBlock>
              By registering, you agree to<br />
              {productName}
              {"'"}s{' '}
              <a href='https://withvector.com/terms-of-service' target='_blank' rel='noopener noreferrer'>
                Terms of Service
              </a>{' '}
              and{' '}
              <a href='https://withvector.com/privacy' target='_blank' rel='noopener noreferrer'>
                Privacy Policy
              </a>.
            </HelpBlock>
          </div>
        </div>
        <Footer className='c-footer--center c-footer--transparent'>
          <div className='tc pa3'>
            Already a member?{' '}
            <Link className='b' data-debug-id='goToRegisterButton' to={{
              pathname: '/sign-in',
              search: window.location.search,
            }}>
              Sign In
            </Link>
          </div>
        </Footer>
      </AuthenticationPage>
    )
  }

  // legacy invite link
  private isMobileInviteLink = () => {
    const { isPortalInvite, inviteId, firstName } = this.state
    return !isPortalInvite && !_.isEmpty(inviteId) && !_.isEmpty(firstName)
  }

  /**
   *  Legacy way to handle deferred invite link
   */
  private handleMobileInviteLink = () => {
    const { email, firmName, firstName, lastName, inviteId } = this.state
    const { location } = this.props
    const { code } = queryString.parse(location.search)

    if (!this.isMobileInviteLink()) {
      return false
    }

    if (Platform.os.family === 'Android') {
      // tslint:disable-next-line:max-line-length
      window.location.replace(
        `https://play.google.com/store/apps/details?id=com.convoy.loaddoc&referrer=utm_source%3D${code}`
      )
      return true
    } else if (Platform.os.family === 'iOS') {
      /*
       * For iOS, unlike Google PlayStore, the App Store will not forward any data to our app after the installation,  branch.io is used instead.
       * The web will ask the server to generate the branch link with the given parameters and open the branch link.
       * This path is basically the same as invite via text message.
       */
      const ufirstName = encodeURIComponent(firstName)
      const ulastName = encodeURIComponent(lastName)
      const uemail = encodeURIComponent(email)
      const ufirm = encodeURIComponent(firmName)
      const uinviteId = encodeURIComponent(inviteId)
      const url = apis.getDynamicLinkInvite(code, ufirstName, ulastName, uemail, ufirm, uinviteId, '')
      if (!_.isEmpty(uemail)) {
        window.location.href = url
        return true
      }
    }

    return false
  }

  private handleFirstNameChange = (firstName) => {
    this.setState({ firstName })
  }

  private handleLastNameChange = (lastName) => {
    this.setState({ lastName })
  }

  private handlePasswordChange = (password) => {
    this.setState({ password })
  }

  private handleRegister = () => {
    const {
      email,
      phoneNumber,
      firstName,
      lastName,
      password,
      userEntity,
      inviteId,
      accessCode,
      pendingUserId,
     } = this.state

    const companyCode = _.get(this.props, 'location.state.companyCode', null)
    const body = _.assign({}, userEntity.user, {
      companyCode,
      email,
      firstName,
      identityType: this.getIdentityType(),
      inviteId,
      accessCode,
      lastName,
      password,
      phoneNumber,
      pendingUserId,
    })
    this.setState({
      firstNameError: null,
      isLoading: true,
      lastNameError: null,
      passwordError: null,
    })
    apis
      .register(body)
      .then(({ isConfirmed }) => {
        if (isConfirmed) {
          const identity = this.getIdentityType() === 'email' ? email : phoneNumber
          return apis.postLogin(identity, password).then(() => {
            browserHistory.push({ pathname: '/redirect' })
          })
        } else {
          browserHistory.push({
            pathname: '/verify',
            search: window.location.search,
            state: {
              identity: this.getIdentityType() === 'email' ? email : phoneNumber,
              nextRoute: _.get(this.props, 'location.state.nextRoute'),
            },
          })
        }
      })
      .catch((e) => {
        const errors = _.get(e, 'responseJSON.errors', null)
        if (errors) {
          _.forEach(errors, (error: any) => {
            if (error.field === 'user.firstName') {
              this.setState({ firstNameError: error.message })
            } else if (error.field === 'user.lastName') {
              this.setState({ lastNameError: error.message })
            } else if (error.field === 'user.password') {
              this.setState({ passwordError: error.message })
            }
          })
        }
      })
      .finally(() => this.setState({ isLoading: false }))
  }

  private getIdentityType = () => {
    const { email, phoneNumber } = this.state
    // We default to email if they fill out both email and phone
    if (email && phoneNumber) {
      return 'email'
    } else if (email && !phoneNumber) {
      return 'email'
    } else if (!email && phoneNumber) {
      return 'phoneNumber'
    } else {
      return 'email'
    }
  }
}

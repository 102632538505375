import _ from 'lodash'
import { Entity } from '../../../models/entity'
import { eventsToEntityBlobs, eventsToESQueryResult } from '../../../models/types/storyboard/yms-utils'

export function eventsToEntities(entity: Entity): any[] {
  const entities = eventsToEntityBlobs(entity)
  return [...entities.values()]
}

export function eventsToQueryResult(entity: Entity): any[] {
  return eventsToESQueryResult(entity)
}


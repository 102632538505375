import _ from 'lodash'
import queryString from 'query-string'
import Cookie from 'js-cookie'
import React from 'react'
import Favicon from 'react-favicon'
import { Redirect, Route, Switch } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { CrashModal } from 'browser/components/atomic-elements/organisms/crash-modal'
import OverlayManager from 'browser/components/atomic-elements/organisms/overlay-manager/overlay-manager'

// Routes
import { AppNavigator } from 'browser/app/pages/app-navigator'
import { App404 } from 'browser/app/pages/app/404'
import { CompanyCode } from 'browser/app/pages/onboarding/company-code/company-code'
import { PasswordRecover } from 'browser/app/pages/onboarding/password-recover'
import { PasswordReset } from 'browser/app/pages/onboarding/password-reset'
import { RegisterCredential } from 'browser/app/pages/onboarding/register-credential/register-credential'
import { Register } from 'browser/app/pages/onboarding/register/register'
import { SignInCredential } from 'browser/app/pages/onboarding/sign-in-credential/sign-in-credential'
import { SignInPassword } from 'browser/app/pages/onboarding/sign-in-password'
import { Verify } from 'browser/app/pages/onboarding/verify'
import { DeferredDeepLink } from 'browser/app/pages/deferred-deep-link/deferred-deep-link'
import { initializeAnalytics } from '../config/analytics'
import apis from '../models/apis'
import { rendererActions } from 'shared-libs/components/renderer-actions'
import { navigateTo, showEntityCreationModal, showToast } from './page-actions'

const SentryRoute = Sentry.withSentryRouting(Route);

const LoadDocsFavicon = require('images/faviconLoaddocs.png')
const VectorFavicon = require('images/faviconVector.png')

export class RootNavigator extends React.Component<IBaseProps, any> {

  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
    }
    OverlayManager.pushReactHost(this)
  }

  public componentDidMount() {
    initializeAnalytics()
    this.initializeActions()
    this.installErrorHandler()
  }

  public componentWillUnmount() {
    OverlayManager.popReactHost()
  }

  public render() {
    return (
      <div
        className='u-height100 js-pagesIndex'
        data-reactroot={true}
      >
        {this.renderFavicon()}
        <Switch>
          <SentryRoute exact={true} path='/' render={this.handleRenderIndexRoute} />
          <SentryRoute path='/company-code' component={CompanyCode} />
          <SentryRoute path='/register' component={Register} />
          <SentryRoute path='/register-credential' component={RegisterCredential} />
          <SentryRoute path='/sign-in' exact={true} component={SignInCredential} />
          <SentryRoute path='/sign-in/password' component={SignInPassword} />
          <SentryRoute path='/sign-in/password-recover' component={PasswordRecover} />
          <SentryRoute path='/sign-in/password-reset' component={PasswordReset} />
          <SentryRoute path='/verify' component={Verify} />

          {/* mobile deferred deep link */}
          <SentryRoute path='/actions/entity/create' component={DeferredDeepLink}/>

          <SentryRoute path='/' component={AppNavigator} />
          <SentryRoute component={App404} />

        </Switch>

        {/* TODO: Prior to 6153 (make superadmin json editor more widely
        available), ModalPortal was unimplemented, so this was also effectively
        disabled. Keeping it disabled for now, since the crash modal is a bit
        misleading with only a refresh button, which may not allow the user to
        recover.
        */}
        {/* <CrashModal isOpen={this.state.hasError} /> */}
      </div>
    )
  }

  private renderFavicon() {
    const url = window.location.origin
    if (url.indexOf('loaddocs') !== -1) {
      return (
        <Favicon url={LoadDocsFavicon} />
      )
    }

    return (
      <Favicon url={VectorFavicon} />
    )
  }

  private installErrorHandler() {
    // Global error handler to display modal on error
    window.onerror = (msg, url, line) => {
      /**
       * https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
       *
       * This error means that ResizeObserver was not able to deliver all observations within a single animation frame.
       * It is benign (your site will not break). – Aleksandar Totic Apr 15 at 3:14
       *
       * Ignore this error "ResizeObserver loop limit".  This error seems to occur on the recent Chrome version
       * 83.0.4103.97 (Official Build) (64-bit)
       */
      if (!_.isNil(msg)  && _.includes(msg.toString(), 'ResizeObserver loop limit')) {
        return true
      }
      this.setState({ hasError: true })
      // return false to propagate error
      return false
    }
  }

  private handleRenderIndexRoute = () => {
    const { routeOverride, access_token } = queryString.parse(location.search)
    let authorization = Cookie.get('Authorization')

    if (access_token) {
      authorization = `Bearer ${access_token}`
      Cookie.set('Authorization', authorization)
      apis.setupAuthorization(authorization)
    }

    if (authorization) {
      return <Redirect to={routeOverride ? routeOverride : '/redirect'}/>
    } else {
      return <Redirect to={'/sign-in'}/>
    }
  }

  private initializeActions() {
    rendererActions.registerAction('navigateTo', navigateTo)
    rendererActions.registerAction('showToast', showToast)
    rendererActions.registerAction('showEntityCreationModal', showEntityCreationModal)
  }
}

import _ from 'lodash'

import { AbstractTriggers } from './abstract-triggers'
import { EntityType } from './entity-type'
import { Entity } from '../entity'

export class SEFLePUP extends EntityType implements AbstractTriggers {
  public beforeChangeTrigger() { return }

  public validateTrigger(entity: Entity, errors: any[]) {
    this.mapCheckSumErrors(entity, errors)
    this.mapRequiredZipErrors(entity, errors)
    this.validateZipConfirmation(entity, errors)
  }

  private mapCheckSumErrors(entity: Entity, errors: any[]) {
    // See https://withvector.atlassian.net/browse/VD-2276
    // Need to map "proNumberChecksum" errors to "proNumber". We recently re-enabled visual
    // error indication per UI component (red backgrounds), but unfortunately the schema
    // solution for the new CHECKDIGIT formula required a separate boolean property,
    // so the error doesn't get propagated back into the component as expected.
    _.forEach(errors, error => {
      _.forEach(error.path, (pathPart, i) => {
        if (pathPart === "proNumberChecksum") {
          error.path[i] = "proNumber"
        }
      })
    })
  }

  private COUNTRY_ZIP_PROPERTY_NAMES = {
    "USA ZIP" : "usaZip",
    "Mexico ZIP" : "mexicoZip",
    "Canada ZIP" : "canadaZip",
  }

  private mapRequiredZipErrors(entity: Entity, errors: any[]) {
    // See https://withvector.atlassian.net/browse/VD-2276
    // Map "zipCode is required" errors to the appropriate country zip property key.
    _.forEach(errors, (error: { errors: any[]; path: string[] }) => {
      const zipCodePartIndex = _.indexOf(error.path, 'zipCode')
      if (zipCodePartIndex >= 0) {
        _.forEach(error.errors, message => {
          if (_.includes(message, "required")) {
              const path = _.slice(error.path, 0, zipCodePartIndex)
              const pickup = _.get(entity, path)
              const countryZipPropertyName = this.COUNTRY_ZIP_PROPERTY_NAMES[pickup.countryZip]
              const zipcode = _.get(pickup, countryZipPropertyName, '')
              if (_.isEmpty(zipcode)) {
                error.path[zipCodePartIndex] = countryZipPropertyName
              }
          }
        })
      }
    })
  }

  private validateZipConfirmation(entity: Entity, errors: any[]) {
    // SEFL wants drivers to re-type the ZIP code per pickup.
    // For each pickup in entity.seflEpup.pickups[], check that the
    // active country ZIP code matches its corresponding confirmation field

    const pickups = _.get(entity, 'seflEpup.pickups', [])
    _.forEach(pickups, (pickup, index) => {
      const countryZipPropertyName = this.COUNTRY_ZIP_PROPERTY_NAMES[pickup.countryZip]
      const zipcode = _.get(pickup, countryZipPropertyName, '')
      const confirmation = _.get(pickup, 'zipConfirmation', '')
      if (_.isEmpty(zipcode) && _.isEmpty(confirmation)) {
        return
      }
      if (!_.isEqual(zipcode, confirmation)) {
        errors.push({
          path: ['seflEpup', 'pickups', index, countryZipPropertyName],
          errors: ['does not match confirmation']
        })
      }
    })
  }
}
import { Classes, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { NavLink } from 'react-router-dom'

import { Query } from 'shared-libs/models/query'

import apis from 'browser/app/models/apis'
import { Settings } from 'browser/app/models/settings'
import { Avatar } from 'browser/components/atomic-elements/atoms/avatar/avatar'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { AddressInput } from 'browser/components/atomic-elements/atoms/input/address-input/address-input'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import {
  default as EntitySelect,
  getNewEntityDefaultValue,
} from 'browser/components/atomic-elements/atoms/select/entity-select'
import { SheetContext } from 'browser/components/atomic-elements/atoms/sheet/sheet-manager'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import { ConfirmationModal } from 'browser/components/atomic-elements/organisms/confirmation-modal'
import 'browser/components/atomic-elements/organisms/entity/entity-associations/_entity-associations-list.scss'
// tslint:disable-next-line:max-line-length
import { EntityAssociationsSheet } from 'browser/components/atomic-elements/organisms/entity/entity-associations/entity-associations-sheet'
import { EntityAssociationsRequest } from './entity-associations-request'

/**
 * @uiComponent
 */
interface IEntityAssociationListProps extends IBaseProps {
  associatedEntityType: string
  buttonText: string
  edgePath: string
  entity: any
  entityType: string
  helpText: string
  iconName: string
  isEdgeOnEntity: boolean
  placeholder: string
  settings: Settings
}

interface IEntityAssociateionListState {
  associatedEntitySchema: any
  connectedEntities: any[]
  entitySchema: any
  isArrayOfEdges: any
  isLoading: boolean
  pendingEntities: any
}

const AddAssociationPopoverTetherOptions = {
  attachment: 'top left',
  targetAttachment: 'bottom left',
}

// We have 3 cases to consider
// 1. edge is on the other entity and it is an edge
// 2. edge is on the other entity and it is an Array<edge>
// 3. edge is on the entity and it is an Array<edge>
// NOTE: if edge is on the entity and it is an edge, it would just be a field
// instead of being on the connection list
export class EntityAssociationList
  extends React.Component<IEntityAssociationListProps, IEntityAssociateionListState> {

  private store: any
  private addAssociationPopover: TetherTarget

  constructor(props) {
    super(props)
    this.store = apis.getStore()
    this.state = this.getNextStateFromProps(props)
  }

  public componentDidMount() {
    void this.fetchAssociatedEntities(this.props)
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      associatedEntityType,
      edgePath,
      entity,
      entityType,
      isEdgeOnEntity,
    } = this.props
    if (associatedEntityType !== nextProps.associatedEntityType ||
      edgePath !== nextProps.edgePath ||
      entity !== nextProps.entity ||
      entityType !== nextProps.entityType ||
      isEdgeOnEntity !== nextProps.isEdgeOnEntity) {
      const nextState = this.getNextStateFromProps(nextProps)
      this.setState(nextState, () => this.fetchAssociatedEntities(nextProps))
    }
  }

  public render() {
    const { isLoading, connectedEntities } = this.state
    if (isLoading) {
      return (
        <div className='u-positionRelative u-innerBumper--xl ba b--light-gray'>
          <LoadingSpinner size='xs' />
        </div>
      )
    }
    if (_.isEmpty(connectedEntities)) {
      return this.renderEmptyState()
    }
    return this.renderAssocationsList()
  }

  private getNextStateFromProps(props) {
    const {
      associatedEntityType,
      edgePath,
      entityType,
      isEdgeOnEntity,
    } = props

    const associatedEntitySchema = this.store.getRecord(associatedEntityType)
    const entitySchema = this.store.getRecord(entityType)
    const edgeEntitySchema = isEdgeOnEntity ? entitySchema : associatedEntitySchema
    const pathFragment = edgePath.split('.').join('.properties.')
    const edgeSchemaPath = `properties.${pathFragment}`
    const edgeDefinition: any = _.get(edgeEntitySchema, edgeSchemaPath)
    const isArrayOfEdges = edgeDefinition.type === 'array'
    return {
      associatedEntitySchema,
      connectedEntities: [],
      entitySchema,
      filter: null,
      isArrayOfEdges,
      isLoading: true,
      pendingEntities: [],
    }
  }

  private async fetchAssociatedEntities(props: IEntityAssociationListProps) {
    const { isEdgeOnEntity, associatedEntityType } = props
    const { isArrayOfEdges, pendingEntities } = this.state

    // lazy-load schema first, just in case the user's bundle doesn't have it
    const associatedEntitySchema = await this.store.resolveSchema(associatedEntityType)
    this.setState({ associatedEntitySchema })

    let promise
    if (isEdgeOnEntity) {
      promise = this.fetchEdgeOnEntity(isArrayOfEdges)
    } else {
      promise = this.fetchEdgeOnAssociatedEntities(isArrayOfEdges)
    }
    promise.then((entities) => {
      entities = entities || []
      this.setState({
        connectedEntities: entities.concat(pendingEntities),
        isLoading: false,
      })
    })
  }

  // fetch associated entities where the edge is on the entity
  private fetchEdgeOnEntity(isArrayOfEdges) {
    // get ids of all of the associated entities from the entity
    const { associatedEntityType, edgePath, entity } = this.props
    const edges = entity.get(edgePath, [])
    const entityIds = _.map(edges, 'entityId')
    if (_.isEmpty(entityIds)) {
      return Promise.resolve([])
    }
    const filters = [{
      path: 'uniqueId',
      type: 'match',
      values: entityIds,
    }]
    const collection = new Query(apis)
      .setEntityType(associatedEntityType)
      .setFilters(filters)
      .getCollection()
    return collection.find()
  }

  // fetch associated entities where the edge is on the associated entity
  private fetchEdgeOnAssociatedEntities(isArrayOfEdges) {
    const { associatedEntityType, edgePath, entity } = this.props
    const filters = [{
      path: edgePath,
      type: isArrayOfEdges ? 'containsEdge' : 'matchEdge',
      value: { entityId: entity.uniqueId },
    }]
    const collection = new Query(apis)
      .setEntityType(associatedEntityType)
      .setFilters(filters)
      .getCollection()
    return collection.find()
  }

  private getEntityAssociationRequest() {
    const { edgePath, isEdgeOnEntity } = this.props
    const { associatedEntitySchema, entitySchema } = this.state
    return new EntityAssociationsRequest({
      associatedEntitySchema, edgePath, entitySchema, isEdgeOnEntity,
    })
  }

  private handleConnectionsChanged = (entity) => {
    const { connectedEntities, pendingEntities } = this.state
    const newConnectedEntitites = _.cloneDeep(connectedEntities)
    const newPendingEntities = _.cloneDeep(pendingEntities)
    if (entity.isNew) {
      newPendingEntities.push(entity)
    }
    newConnectedEntitites.push(entity)
    this.setState({
      connectedEntities: newConnectedEntitites,
      pendingEntities: newPendingEntities,
    })
  }

  private handleAddAssociation = (unused, associatedEntity) => {
    const { entity } = this.props
    const { connectedEntities } = this.state
    this.addAssociationPopover.close()
    const request = this.getEntityAssociationRequest()
    request.addAssociateEntity(entity, associatedEntity)
    this.setState({
      connectedEntities: connectedEntities.concat([associatedEntity]),
    })
  }

  private handleDeleteAssociation = (associatedEntity) => {
    const handlePrimaryClick = () => {
      const { entity } = this.props
      const { connectedEntities } = this.state
      const request = this.getEntityAssociationRequest()
      request.removeAssociateEntity(entity, associatedEntity)
      _.remove(connectedEntities, { uniqueId: associatedEntity.uniqueId })
      this.setState({ connectedEntities })
    }
    ConfirmationModal.open({
      confirmationText: 'Do you want to unassociate this entity?',
      confirmationTitle: 'Review Changes',
      modalDialogClassName: 'c-modal-dialog--sm',
      onPrimaryClicked: handlePrimaryClick,
      primaryButtonText: 'Confirm',
    })
  }

  private handleShowAssociationsPanel = (value: string, openOverlay: any) => {
    const { edgePath, entity, isEdgeOnEntity, settings } = this.props
    const { associatedEntitySchema, entitySchema } = this.state
    this.addAssociationPopover.close()
    const recordTemplate = this.store.createRecord(associatedEntitySchema)
    const defaultValue = getNewEntityDefaultValue(recordTemplate, value)
    openOverlay(
      <EntityAssociationsSheet
        associatedEntityDefaultValue={defaultValue}
        associatedEntitySchema={associatedEntitySchema}
        edgePath={edgePath}
        entity={entity}
        entitySchema={entitySchema}
        isEdgeOnEntity={isEdgeOnEntity}
        onChange={this.handleConnectionsChanged}
        state={{settings}}
      />,
    )
  }

  private renderAddAssociationPopover(openOverlay) {
    const {
      associatedEntityType,
      placeholder,
    } = this.props
    const { connectedEntities } = this.state
    const filter = _.isEmpty(connectedEntities) ? null : {
      path: 'uniqueId',
      type: 'notMatch',
      values: _.map(connectedEntities, 'uniqueId'),
    }
    return (
      <Popover className='collapse'>
        <EntitySelect
          className='c-entityAssociationList-addPopover'
          autoFocus={true}
          entityType={associatedEntityType}
          filter={filter}
          onChange={this.handleAddAssociation}
          onNewOptionClick={(value) => this.handleShowAssociationsPanel(value, openOverlay)}
          placeholder={placeholder}
          showLinkIcon={false}
        />
      </Popover>
    )
  }

  private renderAddAssociationButton(openOverlay) {
    const handleRef = (ref) => { this.addAssociationPopover = ref }
    const { associatedEntitySchema } = this.state
    return (
      <TetherTarget
        closeOnPortalClick={false}
        ref={handleRef}
        tethered={this.renderAddAssociationPopover(openOverlay)}
        tetherOptions={AddAssociationPopoverTetherOptions}
      >
        <Button
          className={Classes.MINIMAL}
        >
          Add {associatedEntitySchema.title}
        </Button>
      </TetherTarget>
    )
  }

  private renderAssocationsList() {
    const { className } = this.props
    const { connectedEntities } = this.state
    const associations = _.map(connectedEntities, this.renderAssociationItem)
    return (
      <SheetContext.Consumer>
        {({ openOverlay }) => (
          <div className={classNames('u-positionRelative', className)}>
            <div className='row'>
              {associations}
            </div>
            {this.renderAddAssociationButton(openOverlay)}
          </div>
        )}
      </SheetContext.Consumer>
    )
  }

  private renderAssociationItem = (entity) => {
    if (entity.entityType === 'Location') {
      return this.renderLocationEntity(entity)
    } else if (entity.entityType === 'Contact') {
      return this.renderContact(entity)
    } else {
      return this.renderContact(entity)
    }
  }

  private renderContact(entity) {
    const handleDelete = () => this.handleDeleteAssociation(entity)
    return (
      <div
        className='col-sm-6 c-boxListItem-container'
        key={entity.uniqueId}
      >
        <div className='c-boxListItem'>
          <NavLink
            className='c-boxListItem-content'
            activeClassName='active'
            target='_blank' rel='noopener noreferrer'
            to={`/entity/${entity.uniqueId}`}
          >
            <div className='u-flex u-alignItemsCenter'>
              <Avatar
                size='xs'
                name={entity.displayName}
                className='u-bumperRight--xs'
              />
              <div className='b'>
                {entity.displayName}
              </div>
            </div>
          </NavLink>
          <Button
            className={classNames(
              'c-button--icon c-boxListItem-button c-boxListItem-button--fadeIn',
              Classes.MINIMAL
            )}
            onClick={handleDelete}
          >
            <Icon
              icon={IconNames.CROSS}
            />
          </Button>
        </div>
      </div>
    )
  }

  private renderLocationEntity(entity) {
    const location = entity.location
    const handleDelete = () => this.handleDeleteAssociation(entity)
    return (
      <div
        className='col-sm-6 c-boxListItem-container'
        key={entity.uniqueId}
      >
        <div className='c-boxListItem'>
          <NavLink
            className='c-boxListItem-content'
            activeClassName='active'
            target='_blank' rel='noopener noreferrer'
            to={`/entity/${entity.uniqueId}`}
          >
            <div>
              <div className='b'>
                {entity.displayName}
              </div>
              <AddressInput
                className='c-addressInput-plainValue'
                showEditableFields={false}
                value={_.get(location, 'address')}
              />
            </div>
          </NavLink>
          <Button
            className={classNames(
              'c-button--icon c-boxListItem-button c-boxListItem-button--fadeIn',
              Classes.MINIMAL
            )}
            onClick={handleDelete}
          >
            <Icon
              icon={IconNames.CROSS}
            />
          </Button>
        </div>
      </div>
    )
  }

  private renderEmptyState() {
    const {
      className,
      iconName,
      helpText,
    } = this.props
    return (
      <SheetContext.Consumer>
        {({ openOverlay }) => (
          <div className={className}>
            <div className='c-boxListItem c-boxListItem--noHover'>
              <div className='c-boxListItem-content u-textCenter'>
                <Icon
                  className='u-bumperTop'
                  icon={IconNames[iconName]}
                />
                <HelpBlock>
                  {helpText}
                </HelpBlock>
                {this.renderAddAssociationButton(openOverlay)}
              </div>
            </div>
          </div>
        )}
      </SheetContext.Consumer>
    )
  }
}

import _ from 'lodash'
import { isEmailValid, isPhoneValid, isUUIDValid } from '../../helpers/utils'
import { SubschemaResolver } from '../../resolvers/json-schema-resolver'

export type ValidationErrorsAtPath = {
  path: string[]
  errors: string[]
}
export type ValidationErrorsMap = Record<string, string[]>

export interface JSONValidator {
  validate(schema: any, instance: {}): Promise<ValidationErrorsAtPath[]> | ValidationErrorsAtPath[]
}

export abstract class TypeValidator {
  protected fieldErrors(errors) {
    if (errors.length > 0) {
      return [{ path: [], errors: errors }]
    } else {
      return []
    }
  }

  protected extractError(schema, defaultError) {
    return _.get(schema, 'errorMessage', defaultError)
  }

  public normalize(instance) {
    return instance
  }

  public abstract validate(props: {}): any[]
}

export class ObjectValidator extends TypeValidator {
  private validateObjectAnyOf(props, result) {
    const { context, schema, instance, validate } = props
    if (_.isEmpty(schema.anyOf)) {
      return
    }
    const isValid = _.map(schema.anyOf, property => {
      const errors = validate({
        context: context,
        schema: property,
        instance: instance
      })
      return _.isEmpty(errors)
    })
    if (!_.some(isValid, Boolean)) {
      result.push({
        path: [],
        errors: ['None of the properties are satisfied']
      })
    }
  }

  private validateObjectAllOf(props, result) {
    const { context, schema, instance, validate } = props
    if (_.isEmpty(schema.allOf)) {
      return
    }
    const errors = _.flatMap(schema.allOf, schema =>
      validate({ context, schema, instance })
    )
    _.forEach(errors, obj => {
      result.push({
        path: obj.path,
        errors: obj.errors,
      })
    })
  }

  private validateObjectProperties(props, result) {
    const { context, schema, instance, normalize, validate } = props

    const pushIsRequired = (key) => {
      if (_.isNil(instance[key])) {
        result.push({
          path: [key],
          errors: ['is required']
        })
      }
    }

    // checking required properties and add default
    _.forEach(schema.required, pushIsRequired)

    if (schema.if) {
      const { properties } = schema.if
      _.forEach(properties, (property, key) => {
        const instanceValue = _.get(instance, key)
        const conditionalValue = _.get(property, 'const')
        if (instanceValue === conditionalValue) {
          const { required } = schema.then
          _.forEach(required, pushIsRequired)
        } else {
          if (schema.else) {
            const { required } = schema.else
            _.forEach(required, pushIsRequired)
          }
        }
      })
    }

    _.forEach(schema.properties, (property, key) => {
      if (_.isNil(instance[key])) {
        return
      }
      // We want to normalize certain type e.g. number and integer
      instance[key] = normalize({
        context: context,
        schema: property,
        instance: instance[key]
      })
      const errors = validate({
        context: context,
        schema: property,
        instance: instance[key]
      })
      for (let i = 0; i < errors.length; ++i) {
        result.push({
          path: [key].concat(errors[i].path),
          errors: errors[i].errors
        })
      }
    })
  }

  public validate(props) {
    let { instance } = props
    const result = []
    if (instance == null) {
      instance = {}
    }
    if (!_.isPlainObject(instance)) {
      result.push({ path: [], errors: ['must be a plain object'] })
      return result
    }
    this.validateObjectAnyOf(props, result)
    this.validateObjectAllOf(props, result)
    this.validateObjectProperties(props, result)
    return result
  }
}

export class BooleanValidator extends TypeValidator {
  public validate(props) {
    const { schema, instance } = props
    const errors = []
    if (typeof instance !== 'boolean') {
      errors.push('must be boolean')
    }
    if (_.has(schema, 'const') && schema.const !== instance) {
      errors.push(this.extractError(schema, `must be ${schema.const}`))
    }
    return this.fieldErrors(errors)
  }
}

export class EnumValidator extends TypeValidator {
  public validate(props) {
    const { schema, instance } = props
    const errors = []
    if (schema.enum.indexOf(instance) < 0) {
      errors.push(schema.errorMessage ? schema.errorMessage : 'value not in list')
    }
    return this.fieldErrors(errors)
  }
}

export class NumberValidator extends TypeValidator {
  protected checkNumber(schema, instance) {
    const errors = []

    if (schema.maximum !== null) {
      if (instance > schema.maximum) {
        errors.push('may be at most ' + schema.maximum)
      } else if (schema.exclusiveMaximum && instance >= schema.maximum) {
        errors.push('must be less than ' + schema.maximum)
      }
    }
    if (schema.minimum !== null) {
      if (instance < schema.minimum) {
        errors.push('must be at least ' + schema.minimum)
      } else if (schema.exclusiveMinimum && instance <= schema.minimum) {
        errors.push('must be more than ' + schema.minimum)
      }
    }
    if (schema.multipleOf != null && (instance / schema.multipleOf) % 1 !== 0) {
      errors.push('must be a multiple of ' + schema.multipleOf)
    }
    return errors
  }

  public normalize(instance) {
    const value = Number(instance)
    return _.isNaN(value) ? instance : value
  }

  public validate({ schema, instance }) {
    let errors = []
    if (typeof instance !== 'number') {
      errors.push('must be a number')
    } else {
      errors = this.checkNumber(schema, instance)
    }
    return this.fieldErrors(errors)
  }
}

export class IntegerValidator extends NumberValidator {
  public normalize(instance) {
    const value = Number(instance)
    return _.isNaN(value) ? instance : value
  }

  public validate({ schema, instance }) {
    let errors = []
    if (typeof instance !== 'number') {
      errors.push('must be a number')
    } else {
      errors = this.checkNumber(schema, instance)
      if (instance % 1 > 0) { errors.unshift('must be an integer') }
    }
    return this.fieldErrors(errors)
  }
}

export class StringValidator extends TypeValidator {
  public validate(props) {
    const { schema, instance } = props
    const errors = []
    if (typeof instance !== 'string') {
      errors.push('must be a string')
    } else {
      if (schema.maxLength != null && instance.length > schema.maxLength) {
        errors.push('may have at most ' + schema.maxLength + ' characters')
      }
      if (schema.minLength != null && instance.length < schema.minLength) {
        errors.push('must have at least ' + schema.minLength + ' characters')
      }
      if (schema.pattern != null && !(RegExp(schema.pattern).test(instance))) {
        errors.push(_.get(schema, 'errorMessage.pattern', 'must match ' + schema.pattern))
      }
      if (schema.format != null) {
        if (schema.format === 'email' && !isEmailValid(instance)) {
          errors.push('must be an email')
        } else if (schema.format === 'phone' && !isPhoneValid(instance)) {
          errors.push('must be a phone number')
        } else if (schema.format === 'uuid' && !isUUIDValid(instance)) {
          errors.push('must be a uuid')
        }
      }
      this.validateNot(schema, instance, errors)
      this.validateAnyOf(schema, instance, errors)
    }
    return this.fieldErrors(errors)
  }

  private validateNot(schema, instance, errors) {
    if (schema.not != null) {
      const not = schema.not
      if (not.enum && not.enum.indexOf(instance) >= 0) {
        errors.push(not.errorMessage ? not.errorMessage : 'value is invalid')
      }
      if (not.anyOf) {
        const valid = _.reduce(not.anyOf, (acc, cur) => {
          const valid = _.isEmpty(this.validate({ schema: cur, instance }))
          return acc && !valid
        }, true)
        if (!valid) {
          errors.push(not.errorMessage ? not.errorMessage : 'value is invalid')
        }
      }
    }
  }

  private validateAnyOf(schema, instance, errors) {
    if (schema.anyOf != null) {
      const valid = _.reduce(schema.anyOf, (acc, cur) => {
        const valid = _.isEmpty(this.validate({ schema: cur, instance }))
        return acc || valid
      }, false)
      if (!valid) {
        errors.push(schema.errorMessage ? schema.errorMessage : 'value is invalid')
      }
    }
  }
}

export class ArrayValidator extends TypeValidator {
  public validate(props) {
    const { context, schema, instance, normalize, validate } = props
    let errors = []
    let i, j
    if (!Array.isArray(instance)) {
      return this.fieldErrors(['must be an array'])
    }
    if (schema.maxItems != null && instance.length > schema.maxItems) {
      errors.push('may have at most ' + schema.maxItems + ' items')
    }
    if (schema.minItems != null && instance.length < schema.minItems) {
      errors.push('must have at least ' + schema.minItems + ' items')
    }
    const result = this.fieldErrors(errors)
    if (schema.items != null) {
      for (i in instance) {
        // We want to normalize certain type e.g. number and integer
        instance[i] = normalize({
          context: context,
          schema: schema.items,
          instance: instance[i]
        })
        errors = validate({
          context: context,
          schema: schema.items,
          instance: instance[i]
        })
        for (j in errors) {
          result.push({
            path: [i].concat(errors[j].path),
            errors: errors[j].errors
          })
        }
      }
    }
    return result
  }
}

// Stub to handle multi types
export class UnionValidator extends TypeValidator {
  public validate() {
    return []
  }
}

export const Validators: Record<string, TypeValidator> = {
  boolean: new BooleanValidator(),
  enum: new EnumValidator(),
  number: new NumberValidator(),
  integer: new IntegerValidator(),
  string: new StringValidator(),
  array: new ArrayValidator(),
  object: new ObjectValidator(),
  union: new UnionValidator(),
}


export class EntityValidator implements JSONValidator {
  private subschemaResolver: SubschemaResolver

  constructor(subschemaResolver: SubschemaResolver) {
    this.subschemaResolver = subschemaResolver
  }

  // TODO(Peter): If we refactor this, we should resolve the entire schema upfront then
  // doing it incrementally.
  public async validate(schema: any, instance: any): Promise<any[]> {
    return this._validate({
      context: schema,
      schema: schema,
      instance: instance
    })
  }

  // TODO(Peter): it is probably more proper to move the normalize out of the
  // validator when we have a better idea on how to refactor
  _normalize = ({ context, schema, instance }) => {
    const resolved = this.subschemaResolver.resolveSubschema(context, schema)
    schema = resolved.schema
    const type = this._getType(schema)
    return Validators[type].normalize(instance)
  }

  _validate = ({ context, schema, instance }) => {
    const resolved = this.subschemaResolver.resolveSubschema(context, schema)
    context = resolved.context
    schema = resolved.schema
    const type = this._getType(schema)
    return Validators[type].validate({
      context,
      schema,
      instance,
      normalize: this._normalize,
      validate: this._validate
    })
  }

  _getType = (schema): string => {
    const schemaType = schema.enum ? 'enum' : (schema.type || 'object')
    return _.isArray(schemaType) ? 'union' : schemaType
  }

}

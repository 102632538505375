import _ from 'lodash'
import { Entity } from '../../entity'
import { evaluateExpressionWithValueGetter } from '../../../helpers/evaluation'
import { CustomFormulas } from '../../../helpers/formulas'
import { Event } from './storyboard-execution-model'
import { jsonPatchToObject } from './storyboard-utils'

type EventToContentHandler = (event: Event) => any

/**
 * Get the entity id that associated with this event
 * @param event
 */
export const getEventEntityId = (event: Event) => {
  return _.get(event, ['associations', 0, 'entityId'])
}

/**
 * Map 'core_storyboard_event_changeSet' to entity json
 *
 * @param event a change set event
 */
const changeSetEventHandler = (event: Event): any => {
  const patch = _.get(event, 'details.patch', [])
  const content = jsonPatchToObject(patch) || {}
  const prevContent = jsonPatchToObject(patch, 'prevValue') || {}
  const entityId = getEventEntityId(event)
  return {
    uniqueId: entityId,
    _prevContent: {
      uniqueId: entityId,
      ...prevContent,
    },
    _changeSetContent: {
      uniqueId: entityId,
      ...content,
    },
  }
}

/**
 * Map 'core_yms_event_trailer_snapshot' to entity json
 */
const snapshotEventHandler = (event: Event): any => {
  const content = _.get(event, 'details', {})
  return {
    uniqueId: getEventEntityId(event),
    ...content,
  }
}

/**
 * default map handler to map event to entity content
 */
const defaultEventContentHandler: Record<string, EventToContentHandler> = {
  core_yms_event_trailer_snapshot: snapshotEventHandler,
  core_storyboard_event_changeSet: changeSetEventHandler,
}

/**
 * Map the events in the execution to json entity data blob.  This is used to map
 * certain event like snapshot and changeset for export purpose.  The entity blob will
 * have these fields
 * {
 *    uniqueId: entity id
 *    _prevContent: The content before the user's modification.
 *    _changeSetContent: The content after the user's modification.
 *    <content>: The content at the time of the snapshot.
 * }
 *
 * @param entity execution entity
 * @param handlers event to entity content mapper
 */
export const eventsToEntityBlobs = (
  entity: Entity,
  handlers: Record<string, EventToContentHandler> = defaultEventContentHandler
): Map<string, object> => {
  const events = entity.get('core_storyboard_execution.events', [])

  return _.reduce(
    events,
    (rows, event) => {
      const eventType = event.eventType
      const handler = handlers[eventType]
      if (_.isNil(handler)) {
        return rows
      }

      const entityId = getEventEntityId(event)

      const data =
        rows.get(entityId) ??
        rows
          .set(entityId, { uniqueId: entityId, _changetSetContent: {}, _prevContent: {} })
          .get(entityId)

      const content = handler?.(event)
      _.merge(data, content)

      return rows
    },
    new Map<string, any>()
  )
}

export const eventsToESQueryResult = (entity: Entity) => {
  const entities = eventsToEntityBlobs(entity)
  return _.map([...entities.values()], (entity) => {
    return {
      data: entity,
      children: [],
      metadata: { totalEntityMatchCount: 1 },
    }
  })
}

export const entityBlobsToRows = async (
  entities: Map<string, any>,
  columns: any[],
  context: any = {}
): Promise<string[][]> => {
  const rows: string[][] = []

  const headers = getColumnHeaders(columns)
  rows.push(headers)

  for (const [key, value] of entities.entries()) {
    const row = await entityBlobToRow(value, columns, context)
    rows.push(row)
  }

  return rows
}

export const entityBlobToRow = async (
  entity = {},
  columns: any[],
  context = {}
): Promise<string[]> => {
  const localContext = {
    _,
    ...context,
    ...entity,
  }
  const valueGetter = (key) => _.get(localContext, key, CustomFormulas[key])

  const row: string[] = []

  for (const entry of columns) {
    const { formula, path } = entry
    try {
      // look for the current content first, then fallback to snapshot, then formula
      const evaluated =
        _.get(localContext, path) ??
        _.get(localContext, '_changeSetContent.' + path) ??
        evaluateExpressionWithValueGetter(formula, valueGetter)
      const value = _.isNil(evaluated?.then) ? evaluated : await Promise.resolve(evaluated)
      row.push(value ?? '')
    } catch (e: any) {
      // for any error, set the column to empty
      row.push('')
    }
  }

  return row
}

export const getColumnHeaders = (columns: any[]): string[] => {
  return columns.map((item) => item.label)
}

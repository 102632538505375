import { Hotkey, Hotkeys, HotkeysTarget } from '@blueprintjs/core'
import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/organisms/app-container/_app-container.scss'
import { NotificationBar } from 'browser/components/atomic-elements/organisms/notification-bar'

/**
 * @uiComponent
 */
interface IAppContainerProps extends IBaseProps {
  location: any
  outlet: React.ReactElement<any>
  sideNavigationBar: React.ReactElement<any>
  topNavigationBar: React.ReactElement<any>
}

@HotkeysTarget
export class AppContainer extends React.Component<IAppContainerProps, any, never> {
  constructor(props) {
    super(props)
    this.state = {
      isChatBarVisible: false,
      isNavigationFixed: props.location.pathname === '/activity',
      isSideNavigationBarVisible: true,
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    // Only the activity page needs the fixed navigation. It's needed to be
    // fixed because of the way we handle the scroll.
    this.setState({
      isNavigationFixed: nextProps.location.pathname === '/activity',
    })
  }

  public render() {
    const { children, className } = this.props
    const { isNavigationFixed, isSideNavigationBarVisible } = this.state
    return (
      <div
        className={classNames('c-outerAppContainer', className, {
          'grid-block vertical': !isNavigationFixed,
        })}
      >
        <div className='grid-block'>
          {this.renderSideNavigationBar()}
          <div
            className={classNames('c-appContainer', {
              'grid-block vertical': !isNavigationFixed,
            })}
          >
            <NotificationBar
              isNavigationFixed={isNavigationFixed}
              isSideNavigationBarVisible={isSideNavigationBarVisible}
            />
            {this.renderTopNavigationBar()}
            <div className='grid-block u-width100'>
              <div className='grid-block u-width100'>
                {children}
              </div>
              {/* <ChatBar
            isVisible={isChatBarVisible}
          /> */}
            </div>
          </div>
        </div>
      </div>
    )
  }

  public renderHotkeys() {
    return (
      <Hotkeys>
        {/* <Hotkey
          global={true}
          combo='shift + c'
          label='Toggle Chat Bar'
          onKeyDown={this.handleToggleChatBar}
        /> */}
        <Hotkey
          global={true}
          combo='shift + s'
          label='Toggle Side Navigation Bar'
          onKeyDown={this.handleToggleSideNavigationBar}
        />
      </Hotkeys>
    )
  }

  private renderTopNavigationBar() {
    const { topNavigationBar } = this.props
    if (!topNavigationBar) {
      return false
    }
    const { isNavigationFixed } = this.state
    return React.cloneElement(topNavigationBar, {
      className: classNames({
        'c-topNavigationBar--fixed': isNavigationFixed,
      }),
      toggleElement: (
        <div
          className='c-topNavigationBar-toggle c-topNavigationBar-link--icon flex items-center justify-center'
          onClick={this.handleToggleSideNavigationBar}
        >
          <Icon
            icon={IconNames.MENU}
          />
        </div>
      ),
    })
  }

  private renderSideNavigationBar() {
    const { sideNavigationBar } = this.props
    const { isNavigationFixed, isSideNavigationBarVisible } = this.state
    if (!sideNavigationBar) {
      return false
    }
    return React.cloneElement(sideNavigationBar, {
      className: classNames({
        'c-sideNavigationBar--fixed': isNavigationFixed,
      }),
      isVisible: isSideNavigationBarVisible,
    })
  }

  private handleToggleChatBar = () => {
    this.setState({ isChatBarVisible: !this.state.isChatBarVisible})
  }

  private handleToggleSideNavigationBar = () => {
    this.setState({ isSideNavigationBarVisible: !this.state.isSideNavigationBarVisible })
  }
}


class RendererActions {
    actions: { [x: string]: Function}

    constructor() {
        this.actions = {}
    }

    public registerAction(name: string, fn: Function) {
        this.actions[name] = fn
    }

    public getAll() {
        return { ...this.actions }
    }
}

export const rendererActions = new RendererActions()

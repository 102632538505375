import _ from 'lodash'

import { AbstractTriggers } from './abstract-triggers'
import { EntityType } from './entity-type'
import { Entity } from '../entity'

export class DocumentImagingSettings extends EntityType implements AbstractTriggers {
  public beforeChangeTrigger() { return }

  public validateTrigger(entity: Entity, errors: any[]) {
    const items = _.get(entity, 'documentImagingSettings.hotkeys', [])
    // key -> freq count
    const documentTypes = {}
    const hotkeys = {}
    _.forEach(items, (item) => {
      if (!_.isNil(item.documentType)) {
        const count = documentTypes[item.documentType.entityId] || 0
        documentTypes[item.documentType.entityId] = count + 1
      }
      if (!_.isNil(item.key)) {
        const count = hotkeys[item.key] || 0
        hotkeys[item.key] = count + 1
      }
    })
    _.forEach(items, (item, index) => {
      if (item.documentType) {
        if (documentTypes[item.documentType.entityId] > 1) {
          errors.push({
            path: ['documentImagingSettings', 'hotkeys', index, 'documentType'],
            errors: ['is not unique']
          })
        }
        if (hotkeys[item.key] > 1) {
          errors.push({
            path: ['documentImagingSettings', 'hotkeys', index, 'key'],
            errors: ['is not unique']
          })
        }
      }
    })
  }
}

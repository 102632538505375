import _ from 'lodash'
import moment from 'moment'
import { relativeDateModesByKey, FIXED, RELATIVE } from '../datetime/relative-date'

const getPath = (object, path) => {
  if (_.isString(path)) {
    path = path.split('.')
  }
  if (!_.isArray(path)) {
    throw new Error(`path must be an array: ${path}`)
  }
  let result = object
  _.forEach(path, (fragment) => {
    if (_.isArray(result)) {
      result = _.map(result, fragment)
    } else {
      result = _.get(result, fragment)
    }
  })
  return result
}

export enum Filter {
  CONTAINS_EDGE = 'containsEdge',
  MATCH = 'match',
  CONTAINS = 'contains',
  MATCH_EDGE = 'matchEdge',
  NOT_CONTAINS_EDGE = 'notContainsEdge',
  NOT_MATCH = 'notMatch',
  NOT_MATCH_EDGE = 'notMatchEdge',
  RANGE = 'range',
  EXISTS = 'exists',
  NOT_EXISTS = 'notExists',
  OR = 'or',
}

export const VALUELESS_FILTERS = new Set([
  Filter.EXISTS,
  Filter.NOT_EXISTS,
  Filter.OR,
])

abstract class BaseFilter {
  protected path: string
  protected isNegation: boolean

  constructor(filter) {
    this.path = filter.path
    this.isNegation = filter.isNegation
  }

  public filter(entity) {
    const isMatch = this._filter(entity)
    return this.isNegation ? !isMatch : isMatch
  }

  protected abstract _filter(entity: any): boolean
}

abstract class ValuesFilter extends BaseFilter {
  protected value: any
  protected values: any[]

  constructor(filter) {
    super(filter)
    this.value = filter.value
    this.values = filter.values
  }
}

class ContainsEdgeFilter extends ValuesFilter {
  public _filter(entity) {
    const entityValues = getPath(entity, this.path)
    if (this.values) {
      return !!_.find(entityValues, (entityValue) => {
        return !!_.find(this.values, { entityId: entityValue.entityId })
      })
    }
    return !!_.find(entityValues, { entityId: this.value.entityId })
  }
}

// tslint:disable-next-line:max-classes-per-file
class MatchEdgeFilter extends ValuesFilter {
  public _filter(entity) {
    const entityValue = getPath(entity, this.path)
    // TODO(Peter): sometimes entityValue is null when entity is initially created
    // this happens when path is precomputed and is not available initially
    // e.g. precomputation.entityType.entityId-11111111-0000-0000-0000-000000000011
    if (_.isNil(entityValue)) { return true }
    if (this.values) {
      return !!_.find(this.values, { entityId: entityValue.entityId })
    }
    return entityValue.entityId === _.get(this.value, 'entityId')
  }
}

// tslint:disable-next-line:max-classes-per-file
class MatchFilter extends ValuesFilter {
  public _filter(entity) {
    const entityValue = getPath(entity, this.path)
    if (this.values) {
      return _.includes(this.values, entityValue)
    }
    return entityValue === this.value
  }
}

// tslint:disable-next-line:max-classes-per-file
class ContainsFilter extends ValuesFilter {
  public _filter(entity) {
    const entityValue = getPath(entity, this.path)
    if (this.values) {
      return _.some(this.values, (val) => _.includes(entityValue, val))
    }
    return _.includes(entityValue, this.value)
  }
}

// tslint:disable-next-line:max-classes-per-file
class RangeFilter extends BaseFilter {
  private gte: any
  private lte: any
  private gt: any
  private lt: any
  private valueType: any

  constructor(filter) {
    super(filter)
    this.gte = filter.gte
    this.lte = filter.lte
    this.gt = filter.gt
    this.lt = filter.lt
    this.valueType = filter.valueType
  }

  public _filter(entity) {
    let value = entity.get(this.path)
    if (this.valueType === 'date') {
      value = moment(value)
    }

    return (this.lte === undefined || value <= this.lte)
    && (this.gte === undefined || value >= this.gte)
    && (this.lt === undefined || value < this.lt)
    && (this.gt === undefined || value > this.gt)
  }
}

class ExistsFilter extends BaseFilter {
  public _filter(entity) {
    const value = entity.get(this.path)
    return !_.isEmpty(value)
  }
}

class RelativeDateFilter extends BaseFilter {
  private startDate: any
  private endDate: any

  constructor(filter) {
    super(filter)
    this.startDate = filter.startDate
    this.endDate = filter.endDate
  }

  public convertRelativeDateToSimple(relativeDate) {
    if (relativeDate.mode === FIXED) {
      return relativeDate.date
    } else if (relativeDate.mode === RELATIVE) {
      const modeCalc = relativeDateModesByKey[relativeDate.offsetUnit]
      return modeCalc.calculateDate(moment.utc(), relativeDate.offsetValue)
    }
  }

  public _filter(entity) {
    const value = entity.get(this.path)
    if (!value) { return false }

    const valueDate = moment(value)
    const startDate = this.convertRelativeDateToSimple(this.startDate)
    const endDate = this.convertRelativeDateToSimple(this.endDate)

    return (startDate === undefined || (valueDate.isAfter(startDate) || valueDate.isSame(startDate)))
     && (endDate === undefined || (valueDate.isBefore(endDate) || valueDate.isSame(endDate)))
  }
}

function negateFilter(filter) {
  const negatedFilter = _.cloneDeep(filter)
  negatedFilter.isNegation = !filter.isNegation
  return negatedFilter
}

export interface IEqlFilter {
  type: string
  path: string
  value?: any
  values?: any[]
  isNegation?: boolean
}

// tslint:disable-next-line:max-classes-per-file
export class Filters {
  public buildFilterFunction(filters, path?) {
    const filterFunctions = []
    _.forEach(filters, (filter) => {
      if (filter.type === Filter.CONTAINS_EDGE) {
        const containsEdgeFilter = new ContainsEdgeFilter(filter)
        filterFunctions.push(containsEdgeFilter.filter.bind(containsEdgeFilter))
      } else if (filter.type === Filter.MATCH) {
        const matchFilter = new MatchFilter(filter)
        filterFunctions.push(matchFilter.filter.bind(matchFilter))
      } else if (filter.type === Filter.CONTAINS) {
        const containsFilter = new ContainsFilter(filter)
        filterFunctions.push(containsFilter.filter.bind(containsFilter))
      } else if (filter.type === Filter.MATCH_EDGE) {
        const matchEdgeFilter = new MatchEdgeFilter(filter)
        filterFunctions.push(matchEdgeFilter.filter.bind(matchEdgeFilter))
      }  else if (filter.type === Filter.NOT_CONTAINS_EDGE) {
        const notContainsEdgeFilter = new ContainsEdgeFilter(negateFilter(filter))
        filterFunctions.push(notContainsEdgeFilter.filter.bind(notContainsEdgeFilter))
      } else if (filter.type === Filter.NOT_MATCH) {
        const notMatchFilter = new MatchFilter(negateFilter(filter))
        filterFunctions.push(notMatchFilter.filter.bind(notMatchFilter))
      } else if (filter.type === Filter.NOT_MATCH_EDGE) {
        const notMatchEdgeFilter = new MatchEdgeFilter(negateFilter(filter))
        filterFunctions.push(notMatchEdgeFilter.filter.bind(notMatchEdgeFilter))
      } else if (filter.type === Filter.RANGE && _.isEmpty(filter.startDate)) {
        const rangeFilter = new RangeFilter(filter)
        filterFunctions.push(rangeFilter.filter.bind(rangeFilter))
      } else if (filter.type === Filter.RANGE && !_.isEmpty(filter.startDate)) {
        const relativeDateFilter = new RelativeDateFilter(filter)
        filterFunctions.push(relativeDateFilter.filter.bind(relativeDateFilter))
      } else if (filter.type === Filter.EXISTS) {
        const existsFilter = new ExistsFilter(filter)
        filterFunctions.push(existsFilter.filter.bind(existsFilter))
      } else if (filter.type === Filter.NOT_EXISTS) {
        const notExistsFilter = new ExistsFilter(negateFilter(filter))
        filterFunctions.push(notExistsFilter.filter.bind(notExistsFilter))
      } else {
        // TODO: OR filters
        console.log(`Unsupported filter type=${filter.type}`)
      }
    })
    return (row) => {
      const entity = path ? row[path] : row
      for (const filterFunction of filterFunctions) {
        if (!filterFunction(entity)) { return false }
      }
      return true
    }
  }

  public execute(result, filters) {
    if (_.isEmpty(filters)) { return result }
    const filterFunction = this.buildFilterFunction(filters, 'data')
    const children = _.filter(result.children, filterFunction)
    return {
      children,
      metadata: {
        totalChildrenCount: children.length,
      },
    }
  }
}

import _ from "lodash"

import { Entity } from "shared-libs/models/entity"
import { RequestMethod } from "shared-libs/models/store"

export type ProgressInfo = {
  progress: number
  hasAttachments: boolean
}

export type SaveWithProgressFn = (entity: Entity, props: any, method: RequestMethod, patchContent?: any, validate?: boolean) => Promise<void>

export function makeProgressAggregator(map: Record<string, ProgressInfo>, onProgressThrottled) {
  return (uploadId: string, progressValue: number, hasAttachmentsValue: boolean) => {
    map[uploadId] = {
      progress: progressValue ?? map[uploadId]?.progress,
      hasAttachments: hasAttachmentsValue || map[uploadId]?.hasAttachments,
    }

    const values = _.values(map)
    const numTotal = _.size(map)
    const numComplete = _.sumBy(values, (value) => (value.progress >= 1 ? 1 : 0))
    const progressSum = _.sumBy(values, (value) => value.progress)
    const aggregateProgress = progressSum / numTotal
    const anySaveHasAttachments = _.some(values, (value) => value.hasAttachments)
    onProgressThrottled({
      progress: aggregateProgress,
      numComplete,
      numTotal,
      hasAttachments: anySaveHasAttachments,
    })
  }
}

declare let window: any

import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { Classes } from '@blueprintjs/core'

import apis from 'browser/app/models/apis'
import 'browser/app/pages/onboarding/sign-in-credential/_sign-in-credential.scss'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { Head } from 'browser/components/atomic-elements/atoms/head/head'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
// tslint:disable-next-line:max-line-length
import { AuthenticationPage } from 'browser/components/atomic-elements/organisms/authentication-page/authentication-page'
import { browserHistory } from 'browser/history'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'

const IDENTITY_PARAM_NAME = 'identity'
const CODE_PARAM_NAME = 'code'

interface ISignInCredentialProps extends IBaseProps {
  location: any
}

interface ISignInCredentialStates {
  errorText: string
  identity: string
  isLoading: boolean
  /* whether the identity is from the querystring and needs its registration queried  */
  identityFromQueryString: boolean
  /* whether we are currently checking registration status */
  isCheckingRegistration: boolean
  accessCode?: string
}

@withRouter
export class SignInCredential extends React.Component<ISignInCredentialProps, ISignInCredentialStates> {

  constructor(props) {
    super(props)
    const { location } = props

    // pull in identity from the querystring as an initial value, if provided
    const { [IDENTITY_PARAM_NAME]: parsedIdentity, [CODE_PARAM_NAME]: accessCode } =
      queryString.parse(window.location.search)
    const prefilledIdentity = parsedIdentity ? decodeURIComponent(parsedIdentity) : undefined

    const identity = _.get(location, 'state.identity', prefilledIdentity)

    this.state = {
      errorText: null,
      identity,
      isLoading: false,
      identityFromQueryString: !!prefilledIdentity,
      isCheckingRegistration: !!prefilledIdentity,
      accessCode,
    }
  }

  componentDidMount(): void {
    const { identity, identityFromQueryString, accessCode } = this.state
    if (identityFromQueryString) {
      // advance to 2FA login if identity from querystring is already registered
      apis
        .getIsRegistered(identity)
        .then(async (response) => {
          if (response['isRegistered']) {
            const redir = response['samlRedirect']
            if (redir) {
              window.location = redir
            } else if (accessCode) {
              this.navigateToVerify(identity, accessCode)
            } else {
              await apis.sendVerificationCode(identity)
              this.navigateToVerify(identity, /* accessCode */ undefined)
            }
          } else {
            this.navigateToRegister(identity, accessCode)
          }
        })
        .finally(() => {
          this.setState({ isCheckingRegistration: false })
        })
    }
  }

  public render() {
    const { errorText, identity, isLoading, isCheckingRegistration } = this.state
    const tetherOptions = {
      attachment: 'middle left',
      targetAttachment: 'middle right',
    }
    const hasErrors = !_.isEmpty(errorText)
    if (isCheckingRegistration) {
      return <LoadingSpinner />
    }
    return (
      <AuthenticationPage
        className='mw5-m mw5-l'
        bodyClassName='c-onboarding-body'
      >
        <Head
          title='Sign in'
        />

        <div
          className={classNames('w-100 mb4 ph4', {
            shake: hasErrors,
          })}
        >
          <h3 className='f3 b lh-title mt4 mb2 tc'>
            Welcome Back
          </h3>
          <div className='lh-copy mb4 mt1 tc'>
            Please sign in to continue to {apis.getProductName()}.
          </div>

          <InputField
            autoComplete='email'
            autoFocus={true}
            className='u-bumperBottom c-formGroup--inputHasBorder u-bumperBottom'
            isDisabled={isLoading}
            data-debug-id='username'
            errorText={errorText}
            onChange={this.handleIdentityChange}
            onEnterPressed={this.handleSignIn}
            placeholder='Mobile number or email'
            size='lg'
            tabIndex={1}
            name='email'
            type='email'
            value={identity}
          />

          <Button
            tabIndex={1}
            className={classNames(Classes.INTENT_PRIMARY, Classes.FILL)}
            data-debug-id='signInButton'
            size='large'
            isLoading={isLoading}
            onClick={this.handleSignIn}
          >
            Next
          </Button>
        </div>

        <Footer className="c-footer--center c-footer--transparent">
          <div className="tc pa3">
            Don&#39;t have an account?{' '}
            <Link
              className="b"
              data-debug-id="goToRegisterButton"
              to={{
                pathname: '/register',
                search: window.location.search,
                state: {
                  nextRoute: _.get(this.props, 'location.state.nextRoute'),
                }
              }}
            >
              Register
            </Link>
          </div>
        </Footer>
      </AuthenticationPage>
    )
  }

  private handleIdentityChange = (identity) => {
    this.setState({ identity })
  }

  private handleSignIn = () => {
    const { location } = this.props
    const { identity } = this.state
    this.setState({ errorText: null, isLoading: true })
    
    apis.getIsRegistered(identity).then(response => {
      if (!response['isRegistered']) {
            this.setState({ errorText: 'Email or phone number is not registered.' })
        return
      }

      const redir = response['samlRedirect']
      if (redir) {
        window.location = redir
        return
      }
      
      // replace history state to include identity
      browserHistory.replace({
        pathname: '/sign-in',
        search: window.location.search,
        state: {
          identity,
          nextRoute: _.get(location, 'state.nextRoute'),
         },
      })
      browserHistory.push({
        pathname: '/sign-in/password',
        search: window.location.search,
        state: {
          identity,
          nextRoute: _.get(location, 'state.nextRoute'),
        },
      })
    }).finally(() => this.setState({ isLoading: false }))
  }

  private navigateToVerify = (identity: string, accessCode: string | undefined) => {
    browserHistory.push({
      pathname: '/verify',
      search: window.location.search,
      state: {
        identity,
        accessCode,
        nextRoute: _.get(this.props, 'location.state.nextRoute'),
      },
    })
  }

  private navigateToRegister(identity: string, accessCode: string) {
    browserHistory.push({
      pathname: '/register',
      search: window.location.search,
      state: {
        identity,
        accessCode,
        nextRoute: _.get(this.props, 'location.state.nextRoute'),
      },
    })
  }
}

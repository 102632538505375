import classNames from 'classnames'
import _ from 'lodash'
import Platform from 'platform'
import queryString from 'query-string'
import React from 'react'
import { Link } from 'react-router-dom'
import { Classes } from '@blueprintjs/core'

import apis from 'browser/app/models/apis'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { ErrorWrapper } from 'browser/components/atomic-elements/atoms/error-wrapper/error-wrapper'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { Head } from 'browser/components/atomic-elements/atoms/head/head'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
import { AuthenticationPage } from 'browser/components/atomic-elements/organisms/authentication-page/authentication-page'
import { browserHistory } from 'browser/history'
import { PlatformType } from 'shared-libs/models/types/storyboard/storyboard-plan'
import { ComponentsContext } from 'browser/contexts/components/components-context'
import { Error as WebFieldError } from 'shared-libs/generated/server-types/webError'
import { ErrorCode } from 'shared-libs/models/api'

const IDENTITY_PARAM_NAME = 'identity'
const IDENTITY_TYPE_PARAM_NAME = 'identityType'

interface IRegisterCredentialProps {
  location: any
}

interface IRegisterCredentialState {
  disableEmailInput: boolean
  disablePhoneInput: boolean
  email: string
  emailError?: WebFieldError
  firmName: string
  invite: any
  isLoading: boolean
  phoneNumber: string
  phoneNumberError?: WebFieldError
  error?: WebFieldError
  /* mobile-web input mode, to mimic the mobile app */
  mode?: 'phone' | 'email'
}

export class RegisterCredential extends React.Component<IRegisterCredentialProps, IRegisterCredentialState> {
  static contextType = ComponentsContext
  context!: React.ContextType<typeof ComponentsContext>

  constructor(props) {
    super(props)

    this.state = {
      disableEmailInput: false,
      disablePhoneInput: false,
      email: null,
      firmName: _.get(location, 'state.firm.displayName', null),
      invite: null,
      isLoading: false,
      phoneNumber: null,
    }

    const { code } = queryString.parse(location.search)
    const companyCode = _.get(props, 'location.state.companyCode', null)
    const firm = _.get(props, 'location.state.firm', null)
    if (!code && (!companyCode || !firm)) {
      browserHistory.push({ pathname: '/company-code', search: window.location.search })
    }
  }

  public componentDidMount() {
    const { location } = this.props
    const { code } = queryString.parse(location.search)
    if (code) {
      this.fetchInvite(code)
    }

    if (this.context.platform === PlatformType.MOBILE_WEB) {
      const {
        [IDENTITY_PARAM_NAME]: parsedIdentity,
        [IDENTITY_TYPE_PARAM_NAME]: parsedIdentityType,
      } = queryString.parse(window.location.search)

      const prefilledIdentity = parsedIdentity ? decodeURIComponent(parsedIdentity) : undefined

      const identityType =
        parsedIdentityType === 'email' || parsedIdentityType === 'phone'
          ? parsedIdentityType
          : 'phone'

      this.setState({
        email: identityType === 'email' ? prefilledIdentity : null,
        phoneNumber: identityType === 'phone' ? prefilledIdentity : null,
        mode: identityType,
      }, () => {
        // if identity is provided, auto-continue
        if (prefilledIdentity && identityType) {
          this.handleMatch()
        }
      })
    }
  }

  public render() {
    const {
      email,
      firmName,
      invite,
      isLoading,
    } = this.state

    // if invite is portal invite, then display the browser version of the registration
    if (invite) {
      const { code } = queryString.parse(location.search)
      const { companyCode } = queryString.parse(location.search)
      if (Platform.os.family === 'Android') {
        // tslint:disable-next-line:max-line-length
        window.location.replace(`https://play.google.com/store/apps/details?id=com.convoy.loaddoc&referrer=utm_source%3D${code}`)
        return <div />
      } else if (Platform.os.family === 'iOS') {
        /*
         * For iOS, unlike Google PlayStore, the App Store will not forward any data to our app after the installation,  branch.io is used instead.
         * The web will ask the server to generate the branch link with the given parameters and open the branch link.
         * This path is basically the same as invite via text message.
         */
        const ufirstName = encodeURIComponent(invite.invite.firstName)
        const ulastName = encodeURIComponent(invite.invite.lastName)
        const uemail = encodeURIComponent(email)
        const ufirm = encodeURIComponent(firmName)
        const uinviteId = encodeURIComponent(invite.uniqueId)
        const url = apis.getDynamicLinkInvite(code, ufirstName, ulastName, uemail, ufirm, uinviteId, companyCode)
        if (!_.isEmpty(uemail)) {
          window.location.href = url
          return <div />
        }
      }
    }

    return (
      <ComponentsContext.Consumer>
        {({ platform }) => (
          <AuthenticationPage
            className='mw5-m mw5-l'
            bodyClassName='c-onboarding-body'
          >
            <Head title='Register Credentials' />
            <div className='ph4 pb4'>
              <h3 className='f3 b lh-title mt4 mb2 tc'>
                Let’s Create Your Account
              </h3>
              {this.renderWelcome(platform)}
              {this.renderInputs(platform)}
              <Button
                buttonText='Continue'
                className={classNames(Classes.INTENT_PRIMARY, Classes.FILL)}
                isLoading={isLoading}
                onClick={this.handleMatch}
                size='large'
              />
            </div>
            <Footer className='c-footer--center c-footer--transparent'>
              <div className='tc pa3'>
                Already a member?{' '}
                <Link
                  className='b'
                  data-debug-id='goToRegisterButton'
                  to={{
                    pathname: '/sign-in',
                    search: window.location.search,
                  }}
                >
                  Sign In
                </Link>
              </div>
            </Footer>
          </AuthenticationPage>
        )}
      </ComponentsContext.Consumer>
    )
  }

  private renderWelcome(platform: PlatformType) {
    const { firmName } = this.state

    const text =
      platform === PlatformType.WEB
        ? `Welcome to ${firmName ?? 'Vector'}!`
        : 'Please enter your mobile phone number or email address.'

    return <div className="lh-copy mb4 mt1 tc">{text}</div>
  }

  private renderInputs(platform: PlatformType) {
    if (platform === PlatformType.WEB) {
      return this.renderWebInputs()
    } else {
      return this.renderMobileWebInputs()
    }
  }

  private renderWebInputs() {
    const {
      disableEmailInput,
      disablePhoneInput,
      email,
      emailError,
      phoneNumber,
      phoneNumberError,
    } = this.state
    return (
      <>
        <ErrorWrapper className="pb2" error={this.mapError(emailError)}>
          <InputField
            className={classNames('c-formGroup--inputHasBorder', {
              'u-bumperBottom': !emailError,
            })}
            isDisabled={disableEmailInput}
            errorText={emailError?.message}
            isHorizontalLayout={false}
            label="Email Address"
            labelProps={{ className: 'c-label--heading' }}
            placeholder="Enter your email address"
            onChange={this.handleEmailChange}
            size="lg"
            value={email}
          />
        </ErrorWrapper>
        <ErrorWrapper error={this.mapError(phoneNumberError)} style={{ marginBottom: 28 }}>
          <InputField
            className={classNames('c-formGroup--inputHasBorder', {
              'u-bumperBottom': !phoneNumberError,
            })}
            isDisabled={disablePhoneInput}
            errorText={phoneNumberError?.message}
            isHorizontalLayout={false}
            label="Mobile Phone Number"
            labelProps={{ className: 'c-label--heading' }}
            placeholder="Enter your mobile phone number"
            onChange={this.handlePhoneNumberChange}
            size="lg"
            value={phoneNumber}
          />
        </ErrorWrapper>
      </>
    )
  }

  private renderMobileWebInputs() {
    const {
      disableEmailInput,
      disablePhoneInput,
      email,
      emailError,
      phoneNumber,
      phoneNumberError,
      error,
      mode,
    } = this.state

    // since one input is visible at a time, just display any available error,
    // since the error 'field' from server can be 'email', e.g. empty input.
    const anyError = phoneNumberError ?? emailError ?? error
    const toggleText = mode === 'phone' ? 'Register with Email Address' : 'Register with Phone Number'
    const input =
      mode === 'phone' ? (
        <ErrorWrapper error={this.mapError(anyError)}>
          <InputField
            className={classNames('c-formGroup--inputHasBorder mb2', {
              'u-bumperBottom': !anyError
            })}
            isDisabled={disablePhoneInput}
            errorText={anyError?.message}
            isHorizontalLayout={false}
            label="Contact Information"
            labelProps={{ className: 'c-label--heading' }}
            placeholder="Enter your mobile phone number"
            type="tel"
            onChange={this.handlePhoneNumberChange}
            size="lg"
            value={phoneNumber}
          />
        </ErrorWrapper>
      ) : (
        <ErrorWrapper error={this.mapError(anyError)}>
          <InputField
            className={classNames('c-formGroup--inputHasBorder mb2', {
              'u-bumperBottom': !anyError,
            })}
            isDisabled={disableEmailInput}
            errorText={anyError?.message}
            isHorizontalLayout={false}
            label="Contact Information"
            labelProps={{ className: 'c-label--heading' }}
            placeholder="Enter your email address"
            onChange={this.handleEmailChange}
            size="lg"
            value={email}
          />
        </ErrorWrapper>
      )

    return (
      <>
        {input}
        <a className="w-100 u-displayBlock tc mb4" onClick={this.handleToggleMode}>
          {toggleText}
        </a>
      </>
    )
  }

  /* Add a link to go back to the sign-in page w/ prefill */
  private mapError(error: WebFieldError | undefined): string | JSX.Element | undefined {
    // TODO: this is a hack, need to augment the error response from the server
    // to send back a more specific error code to match on. For now, match on
    // the error messaging.
    let credentialType
    if (error?.code === ErrorCode.EMAIL_ALREADY_REGISTERED) {
      credentialType = 'email'
    } else if (error?.code === ErrorCode.PHONE_ALREADY_REGISTERED) {
      credentialType = 'phone number'
    }

    if (credentialType) {
      return (
        <span>
          Account exists under this {credentialType}, please go back to{' '}
          <a onClick={this.handleGoBackToSignIn}>Sign In</a>
        </span>
      )
    } else {
      return error?.message
    }
  }

  private handleGoBackToSignIn = () => {
    const { location } = this.props
    const { email, phoneNumber } = this.state
    const { companyCode } = queryString.parse(location.search)
    browserHistory.push({
      pathname: '/sign-in',
      search: window.location.search,
      state: {
        companyCode,
        identity: email || phoneNumber,
      },
    })
  }

  private handleToggleMode = () => {
    const { mode } = this.state
    this.setState({ mode: mode === 'phone' ? 'email' : 'phone' })
  }

  private handleEmailChange = (value) => {
    this.setState({ email: value })
  }

  private handlePhoneNumberChange = (value) => {
    this.setState({ phoneNumber: value })
  }

  private fetchInvite(code) {
    apis.getInviteByCode(code).then((response) => {
      const { invite, firm } = response
      const { email, phoneNumber } = invite.invite
      const { legalName } = firm.business
      this.setState({
        disableEmailInput: email ? true : false,
        disablePhoneInput: phoneNumber ? true : false,
        email,
        firmName: legalName,
        invite,
        phoneNumber: _.get(phoneNumber, 'phone', null),
      })
    })
  }

  private handleMatch = () => {
    const { location } = this.props
    const { email, invite, phoneNumber, mode } = this.state
    const companyCode = _.get(location, 'state.companyCode', null)
    const { code } = queryString.parse(location.search)

    this.setState({ isLoading: true, emailError: undefined, phoneNumberError: undefined, error: undefined })

    if (code) {
      browserHistory.push({
        pathname: '/register',
        search: window.location.search,
        state: {
          companyCode,
          email,
          invite,
          firm: _.get(location, 'state.firm'),
          accessCode: code,
          phoneNumber,
          nextRoute: _.get(location, 'state.nextRoute'),
        },
      })
    } else {
      // include either identity type on web, or only include the active identity type on mobile-web
      const emailInput = !mode || mode === 'email' ? email : undefined
      const phoneInput = !mode || mode === 'phone' ? phoneNumber : undefined
      apis.getMatch({ companyCode, email: emailInput, phoneNumber: phoneInput })
        .then((result: any) => {
          const { firstName, lastName, identity, identityType, firm } = result
          const noMatchIdentityType = () => {
            if (email && !_.isEmpty(email)) {
              return 'email'
            } else if (phoneNumber && !_.isEmpty(phoneNumber)) {
              return 'phoneNumber'
            }
            return 'email'
          }

          // override identity in queryString if changed
          const params = queryString.parse(window.location.search)
          params.identity = emailInput ?? phoneInput
          const search = queryString.stringify(params)

          browserHistory.push({
            pathname: '/register',
            search,
            state: {
              companyCode,
              email: emailInput,
              firm,
              firstName,
              identity,
              identityType: identityType ? identityType : noMatchIdentityType(),
              lastName,
              phoneNumber: phoneInput,
              nextRoute: _.get(location, 'state.nextRoute'),
            },
          })
        }).catch((e) => {
          const errors = _.get(e, 'responseJSON.errors', null)
          if (errors) {
            _.forEach(errors, (error: any) => {
              if (error.field === 'email') {
                this.setState({ emailError: error })
              } else if (error.field === 'phoneNumber') {
                this.setState({ phoneNumberError: error })
              } else {
                this.setState({ error: error })
              }
            })
          }
        }).finally(() => {
          this.setState({ isLoading: false })
        })
    }
  }

}

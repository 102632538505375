import _ from "lodash";

import { WorkflowRoute } from "shared-libs/models/types/storyboard/storyboard-utils";

export function getSchema(route: WorkflowRoute, path: string) {
  // TODO - belongs on WorkflowRoute, but there's too much work to do at the moment to add types to all variables and expose all inner props
  return _.get(route?.task, path)
    || _.get(route?.scene, path)
    || _.get(route?.story, path)
}

import _ from 'lodash'

const GOOGLE_PLACE_RESULT_MAPPINGS = {
  street_number: {
    addressKey: 'streetAddress', valueKey: 'long_name',
  },
  route: {
    addressKey: 'streetAddress', valueKey: 'long_name',
  },
  locality: {
    addressKey: 'locality', valueKey: 'long_name',
  },
  administrative_area_level_1: {
    addressKey: 'region', valueKey: 'short_name',
  },
  country: {
    addressKey: 'countryName', valueKey: 'long_name',
  },
  postal_code: {
    addressKey: 'postalCode', valueKey: 'long_name',
  },
}

export function getAddressFromGooglePlace(place) {
  const address: any = {}
  const addressComponents = place.address_components
  if (_.isEmpty(addressComponents)) return
  const latitude = place.geometry.location.lat()
  const longitude = place.geometry.location.lng()
  if (_.isNumber(latitude) && _.isNumber(longitude)) {
    address.geolocation = { latitude, longitude }
  }
  addressComponents.forEach((component) => {
    const type = component.types[0]
    const resultMapping = GOOGLE_PLACE_RESULT_MAPPINGS[type]
    if (!resultMapping) { return }
    const currentValue = address[resultMapping.addressKey]
    const value = component[resultMapping.valueKey]
    address[resultMapping.addressKey] = currentValue
      ? `${currentValue} ${value}`
      : value
  })
  return address
}
